import "./sidebar.scss"
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import FolderIcon from '@mui/icons-material/Folder';
import ContactsIcon from '@mui/icons-material/Contacts';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../../redux/features/authSlice'
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import LocalStorageDataService from '../common-function/GetLocalstorage';
// import { Ballot, Assignment, MenuBook,BadgeIcon,LibraryBooksIcon } from "@mui/icons-material";
import Logo from '../../images/fevicon.png'
import { getMenuListForSideBar } from "../../redux/features/sidebarSlice";

let user = LocalStorageDataService.userDetail();
let tmsRole = user && user.tms_role
const tmsRoleId = user && user.tms_role_id
const roleWiseFilter = tmsRole && `{"filter":{"status":{"value":"True","operation": "eq"},"role_id":{"value":${tmsRoleId},"operation": "eq"}},"no_limit":True}`

const Sidebar = () => {
  let  siderData = []
 {tmsRole.role_key === 'candidate' ? 
 siderData = [{
  icon: 'DashboardIcon',
  menu: 'Dashboard',
  link: '/dashboard',
  status: 'active',
  submenu: [

  ],
  menuItem: [{
    icon: 'AccountCircleIcon',
    menu: 'Profile',
    link: '/profile',
    status: 'active',
  },
  {
    icon: 'AccountCircleIcon',
    menu: 'My Test',
    link: '/my-test',
    status: 'active',
  },
  {
    icon: 'LogoutIcon',
    menu: 'Logout',
    link: '/',
    status: 'active',
  }]

 }]
 :
   siderData = [
    {
      icon: 'DashboardIcon',
      menu: 'Dashboard',
      link: '/dashboard',
      status: 'active',
      submenu: [
        {
          icon: 'SupervisedUserCircleIcon',
          title: 'Master',
          status: 'active',
          submenus: [
            {
              menu: 'Technology',
              link: '/technology',
              icon: '',
              status: 'active',
            },
            {
              menu: 'Question Level',
              link: '/question-level',
              status: 'active',
            },
            {
              menu: 'Question Type',
              link: '/question-type',
              status: 'active',
            },
            {
              menu: 'Sets',
              link: '/sets',
              status: 'active',
            },
            {
              menu: 'Questions',
              link: '/questions',
              status: 'active',
            }

          ]
        },
        {
          icon: 'ControlCameraIcon',
          title: 'Manage Roles',
          status: 'active',
          submenus: [
            {
              menu: 'All Roles',
              link: '/roles',
              icon: '',
              status: 'active',
            },
            {
              menu: 'Add New Role',
              link: '/add-new-role',
              icon: '',
              status: 'active',
            }
          ]
        }],
      menuItem: [{
        icon: 'AccountCircleIcon',
        menu: 'Profile',
        link: '/profile',
        status: 'active',
      },
      {
        icon: 'AccountCircleIcon',
        menu: 'Candidate Test',
        link: '/candidate_exam_schedule',
        status: 'active',
      },
      // {
      //   icon: 'AccountCircleIcon',
      //   menu: 'My Test',
      //   link: '/my-test',
      //   status: 'active',
      // },
      {
      
        icon: 'ContactsIcon',
        menu: 'Users',
        link: '/users',
        status: 'active',
      },
      {
        icon: 'ContactsIcon',
        menu: 'Candidate',
        link: '/candidate',
        status: 'active',
      },

      // {
      //   icon: 'ContactsIcon',
      //   menu: 'Attempt Test',
      //   link: '/test',
      //   status: 'active',
      // },

      // {
      //   icon: 'ContactsIcon',
      //   menu: 'Term & Condition',
      //   link: '/term-and-condition',
      //   status: 'active',
      // },
      {
        icon: 'LogoutIcon',
        menu: 'Logout',
        link: '/',
        status: 'active',
      }]
    }]
  }

  const { logoutUser, authLoggedIn } = useSelector((state) => state.authUser)
  // const { sidebarMenuList } = useSelector((state) => state.sidebar)

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const logout_user = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  if (authLoggedIn === false && logoutUser && logoutUser.message) {
    navigate('/')
  }

  const { data } = useSelector((state) => state.navbartoggle);

  useEffect(() => {
    dispatch(getMenuListForSideBar(roleWiseFilter))
  }, [dispatch])

  const subItemsMapping = (item, index) => {
    return <MenuItem key={index}>
      {item.menu}
      {item.link && <Link to={item.link}></Link>}
    </MenuItem>
  }

  return (

    // dynamic side bar menu code using api 
    <div className={data === true ? 'sidebar-collapse sidebar' : 'sidebar'} >
      <div className="top">
        <Link to="/dashboard" style={{ textDecoration: "none" }}>
          <span className="logo"><img src={Logo} alt={Logo} /><span className="logo-text">CES</span></span>
        </Link>
      </div>

      {/* side bar code api end */}
      {/* <ProSidebar>
        <Menu>
          {sidebarMenuList && sidebarMenuList.length > 0 && sidebarMenuList.map((item, index) => {
            return (
              item.submenu.length === 0 ?
                <MenuItem key={index} icon={
                  item.icon === "DashboardIcon" ? <DashboardIcon /> :
                    item.icon === "SupervisedUserCircleIcon" ? <SupervisedUserCircleIcon /> :
                      item.icon === "ControlCameraIcon" ? <ControlCameraIcon /> :
                        item.icon === "FolderIcon" ? <FolderIcon /> :
                          item.icon === "ContactsIcon" ? <ContactsIcon /> :
                            item.icon === "CardMembershipIcon" ? <CardMembershipIcon /> :
                              item.icon === "AutorenewIcon" ? <AutorenewIcon /> :
                                item.icon === "CalendarMonthIcon" ? <CalendarMonthIcon /> :
                                  item.icon === "BadgeIcon" ? <BadgeIcon /> :
                                    item.icon === "Assignment" ? <Assignment /> :
                                      item.icon === "MenuBook" ? <MenuBook /> :
                                        item.icon === "AccountCircleIcon" ? <AccountCircleIcon /> :
                                          item.icon === "Ballot" ? <Ballot /> :
                                            item.icon === "EngineeringIcon" ? <EngineeringIcon /> :
                                              item.icon === "LibraryBooksIcon" ? <LibraryBooksIcon /> :
                                                <PictureAsPdfIcon />}>

                  {item.title}
                  <Link to={"/" + item.link} />
                </MenuItem>
                : item.submenu.length > 0 &&
                <SubMenu key={index} title={item.title} icon={
                  item.icon === "DashboardIcon" ? <DashboardIcon /> :
                    item.icon === "SupervisedUserCircleIcon" ? <SupervisedUserCircleIcon /> :
                      item.icon === "ControlCameraIcon" ? <ControlCameraIcon /> :
                        item.icon === "FolderIcon" ? <FolderIcon /> :
                          item.icon === "ContactsIcon" ? <ContactsIcon /> :
                            item.icon === "CardMembershipIcon" ? <CardMembershipIcon /> :
                              item.icon === "AutorenewIcon" ? <AutorenewIcon /> :
                                item.icon === "CalendarMonthIcon" ? <CalendarMonthIcon /> :
                                  item.icon === "BadgeIcon" ? <BadgeIcon /> :
                                    item.icon === "Assignment" ? <Assignment /> :
                                      item.icon === "MenuBook" ? <MenuBook /> :
                                        item.icon === "AccountCircleIcon" ? <AccountCircleIcon /> :
                                          item.icon === "Ballot" ? <Ballot /> :
                                            item.icon === "EngineeringIcon" ? <EngineeringIcon /> :
                                              item.icon === "LibraryBooksIcon" ? <LibraryBooksIcon /> :
                                                <PictureAsPdfIcon />}>

                  {item.submenu.map((subItem, subIndex) => {
                    if (!subItem.link.match("edit") && !subItem.link.match("view")) {
                      //   console.log(subItem.link.match("edit"), "subbbbbbbb")
                      return (
                        subItem.menuItem.length === 0 ?

                          <MenuItem key={subIndex}>
                            {subItem.title}
                            <Link to={"/" + subItem.link} />
                          </MenuItem>

                          : subItem.menuItem.length > 0 &&

                          <SubMenu key={subIndex} title={subItem.title}>

                            {subItem.menuItem.map((submenusItem, submenusIndex) => {
                              if (!submenusItem.link.match("edit") && !submenusItem.link.match("view")) {
                                return (
                                  <MenuItem key={submenusIndex} >
                                    {submenusItem.title}
                                    {submenusItem.link && <Link to={"/" + submenusItem.link}></Link>}
                                  </MenuItem>
                                )
                              }
                              return true;
                            })
                            }

                          </SubMenu>

                        // <MenuItem key={subIndex}>
                        //   {subItem.title}
                        //   <Link to={"/" + subItem.link} />
                        // </MenuItem>
                      )
                    }
                    return true;
                  })}
                </SubMenu>
            )
          }
          )}
          <MenuItem icon={<LogoutIcon />} onClick={logout_user}>
            Logout
            <Link to={"/"} />
          </MenuItem>
        </Menu>
      </ProSidebar> */}

      <ProSidebar>
        {siderData.map((item, index) => {
          return (
            <Menu key={index}>
              <MenuItem icon={<DashboardIcon />}>
                {item.menu}
                <Link to={item.link} />
              </MenuItem>
              {item.submenu && item.submenu.map((subItem, subIndex) => {
                return (
                  <SubMenu key={subIndex} title={subItem.title}
                    icon={subItem.icon === "SupervisedUserCircleIcon" ? <SupervisedUserCircleIcon /> :
                      subItem.icon === "ControlCameraIcon" ? <ControlCameraIcon /> :
                        subItem.icon === "FolderIcon" ? <FolderIcon /> :
                          subItem.icon === "ContactsIcon" ? <ContactsIcon /> :
                            subItem.icon === "CardMembershipIcon" ? <CardMembershipIcon /> :
                              subItem.icon === "AutorenewIcon" ? <AutorenewIcon /> :
                                subItem.icon === "CalendarMonthIcon" ? <CalendarMonthIcon /> :
                                  <PictureAsPdfIcon />}>
                    {subItem.icon === 'SupervisedUserCircleIcon' &&
                      subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
                        return subItemsMapping(subItems, subIndexs)
                      })
                    }
                    {subItem.icon === 'ControlCameraIcon' &&
                      subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
                        return subItemsMapping(subItems, subIndexs)

                      })
                    }

                    {subItem.icon === 'FolderIcon' &&
                      subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
                        return subItemsMapping(subItems, subIndexs)
                      })
                    }
                    {subItem.icon === 'ContactsIcon' &&
                      subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
                        return subItemsMapping(subItems, subIndexs)
                      })
                    }
                    {subItem.icon === 'CardMembershipIcon' &&
                      subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
                        return subItemsMapping(subItems, subIndexs)
                      })
                    }
                    {subItem.icon === 'AutorenewIcon' &&
                      subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
                        return (
                          <SubMenu key={subIndexs} title={subItems.title}>
                            {subItems.submenus && subItems.submenus.map((l, i) => {
                              return subItemsMapping(l, i)
                            })}
                          </SubMenu>
                        )
                      })
                    }

                    {subItem.icon === 'CalendarMonthIcon' &&
                      subItem.menuItem && subItem.menuItem.map((subItems, subIndexs) => {
                        return subItemsMapping(subItems, subIndexs)
                      })
                    }

                    {subItem.icon === 'CalendarMonthIcon' &&
                      subItem.submenus && subItem.submenus.map((l, i) => {
                        return (
                          <SubMenu key={i} title={l.title} >
                            {l.submenus && l.submenus.map((lx, ix) => {
                              return subItemsMapping(lx, ix)
                            })}
                          </SubMenu>
                        )
                      })}

                    {subItem.icon === 'PictureAsPdfIcon' &&
                      subItem.submenus && subItem.submenus.map((subItems, subIndexs) => {
                        return subItemsMapping(subItems, subIndexs)
                      })
                    }

                  </SubMenu>
                )
              })}
              {item.menuItem && item.menuItem.map((itm, i) => {
                return (
                  <MenuItem onClick={() => itm.menu == "Logout" ? logout_user() : {}} key={i} icon={itm.icon == "EngineeringIcon" ? <EngineeringIcon /> : itm.icon == "AccountCircleIcon" ? <AccountCircleIcon /> : itm.icon == "ContactsIcon" ? <ContactsIcon /> : <LogoutIcon />}>
                    {itm.menu}
                    <Link to={itm.link} />
                  </MenuItem>
                )
              })}
            </Menu>
          )
        })}
      </ProSidebar>
    </div>
  )
}

export default Sidebar
