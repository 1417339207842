import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./resource.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoaderForm from "../../components/common-function/LoaderForm";
import { candidateUpdateById, candidateCreate } from "../../redux/features/resourceSlice";
import "react-datepicker/dist/react-datepicker.css";
import DataService from "../../redux/services/data.service";
import { toast, ToastContainer } from "react-toastify";
import CONSTANTS from "../../components/constant/constantComponents";
import { STATUSES } from "../../redux/common-status/constant";
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { roleListOption } from "../../redux/features/optionsSlice";

const initialFormData = Object.freeze({
    first_name: "",
    last_name: "",
    email: "",
    contact_no: "",
    tms_role: "",
    password: ""
});

const formErrData = Object.freeze({
    f_nameErr: "First name is required",
    l_nameErr: "Last name is required",
    emailErr: 'Email required',
    contact_noErr: "Contact no is required",
    tms_roleErr: "Role is required",
    passwordErr: "Password is required",
});

const CandidateEdit = ({ resId }) => {

    const dispatch = useDispatch()
    const { resourceid } = useParams()
    const navigate = useNavigate()
    const { view_data, status, error, createCandidate, updateCandidate } = useSelector(state => state.resource)
    const { roles_candidate } = useSelector(state => state.dropDownOption)
    const [formValues, setFormValues] = useState(initialFormData)
    const [loader, setLoader] = useState(false)
    const [formErr] = useState(formErrData)
    const [errSatus, setErrSatus] = useState(false);
    const [validateErr, setValidateErr] = useState("")

    const loadData = async () => {
        const res = await DataService.candidate_view(resourceid)
        if (res && res.data && res.data.data) {
            const editData = res.data.data
            setFormValues({
                first_name: editData && editData.first_name,
                last_name: editData && editData.last_name,
                email: editData && editData.email,
                contact_no: editData && editData.contact_no,
                tms_role: editData && editData.tms_role_id,
                password: editData && editData.password,
                user:editData && editData.user_id
            })

        }
    }

    useEffect(() => {
        if (resourceid) {
            loadData()
        }
        dispatch(roleListOption())
        if (view_data && view_data.data) {
            setFormValues(loadEditResourceData)

        }
    }, [dispatch])

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value });
    }


    const validate = (email, contact) => {
        const errors = {}

        if (!email) {
            errors.email = 'Required'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors.email = 'Invalid email address'
        } else {
            errors.email = true
        }

        var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
        if (!pattern.test(contact)) {
            errors.contact = "Please Enter Number Only";
        } else if (contact.length !== 10) {
            errors.contact = "Please enter valid  Mobile Number.";
        } else {
            errors.contact = true
        }


        return errors
    }

    const submitData = (e) => {
        e.preventDefault()
        setFormValues({ ...formValues, tms_role: roles_candidate && roles_candidate.length > 0 && roles_candidate[0].value })
        if (formValues.first_name === "" || formValues.last_name === "" || formValues.email === "" || formValues.password === "" || formValues.tms_role === "") {
            setErrSatus(true)
        }

        if (formValues.first_name && formValues.last_name && formValues.email && formValues.password && formValues.contact_no) {
            const isValidate = validate(formValues.email, formValues.contact_no)

            if (isValidate.email === true && isValidate.contact === true) {
                let addvalues = {
                    ...formValues, first_name: formValues.first_name, last_name: formValues.last_name,
                    tms_role: roles_candidate && roles_candidate.length > 0 && roles_candidate[0].value,
                    status: true, registration_status: 0, created_by: GetLocalstorage.userDetail().id, resume_file: null
                }
                setErrSatus(false)
                setValidateErr("")
                setLoader(true)
                if (resourceid) {
                    dispatch(candidateUpdateById({ id: resourceid, addvalues: addvalues }))
                } else {
                    dispatch(candidateCreate(addvalues))
                }

            } else {
                setErrSatus(true)
                setValidateErr(isValidate)
            }


        }

    }

    if (loader === true && status === STATUSES.FAILURE && error && error.message) {
        setLoader(false)
        toast.error(error.message);
    }

    if (loader === true && status === `${STATUSES.SUCCESS}_createCandidate` && createCandidate && createCandidate.message) {
        if (createCandidate.status === 200) {
            toast.success(createCandidate.message);
            setTimeout(() => {
                navigate('/candidate')
            }, 1000)
        } else {
            toast.error(createCandidate.message)
        }
        setLoader(false)
    }

    if (loader === true && status === `${STATUSES.SUCCESS}_updateCandidate` && updateCandidate && updateCandidate.message) {
        if (updateCandidate.status === 200) {
            toast.success(updateCandidate.message);
        } else {
            toast.error(updateCandidate.message)
        }
        setLoader(false)
        setTimeout(() => {
            // loadData()
            navigate('/candidate')
        }, 2000)
    }

    const goback = () => {
        if (resId) {
            navigate('/profile')
        } else {
            navigate('/candidate')
        }
    }

    const loadEditResourceData = () => {
        const data = view_data.data
        const editFormValues = {
            first_name: data && data.first_name,
            last_name: data && data.last_name,
            email: data && data.email,
            contact_no: data && data.contact_no,
            tms_role: data && data.tms_role && data.tms_role.id,
            password: data && data.password

        }
        return editFormValues
    }


    return (
        <div className="resource">
            <Sidebar />
            <div className="resourceContainer">
                <Navbar />
                <div className="top-headings">
                    <h3>{resId ? "Edit Profile" : resourceid ? "Edit candidate" : "Add candidate"}</h3>

                    <Tooltip title="Back">
                        <button onClick={goback} className="btn btn-add-new"><ArrowBackIcon /></button>
                    </Tooltip>
                </div>
                <ToastContainer />
                {loader === true && <LoaderForm />}
                <div>
                    {(formValues.email || !resourceid) ?
                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <label >
                                        First Name
                                    </label>
                                    <input
                                        name="first_name"
                                        defaultValue={formValues.first_name}
                                        type="text"
                                        placeholder="First Name"
                                        onChange={handleChange}
                                    />
                                    {errSatus === true && formValues && formValues.first_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.f_nameErr}</p>}
                                </div>
                                <div className="formInput">
                                    <label >
                                        Last Name
                                    </label>
                                    <input
                                        name="last_name"
                                        defaultValue={formValues.last_name}
                                        type="text"
                                        placeholder="Last Name"
                                        onChange={handleChange}
                                    />
                                    {errSatus === true && formValues && formValues.last_name === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.l_nameErr}</p>}
                                </div>
                                <div className="formInput">
                                    <label >
                                        Email
                                    </label>
                                    <input
                                        name="email"
                                        type="email"
                                        defaultValue={formValues.email}
                                        placeholder="Email"
                                        onChange={handleChange}
                                    />
                                    {errSatus === true && formValues && formValues.email === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.emailErr}</p>}
                                    {errSatus === true && validateErr && validateErr.email !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.email}</p>}
                                </div>



                                <div className="formInput">
                                    <label >
                                        Contact No
                                    </label>
                                    <input
                                        name="contact_no"
                                        defaultValue={formValues.contact_no}
                                        type="text"
                                        placeholder="Contact No"
                                        onChange={handleChange}
                                    />
                                    {errSatus === true && formValues && formValues.contact_no === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.contact_noErr}</p>}
                                    {errSatus === true && validateErr && validateErr.contact !== true && <p style={{ color: "#96332c", fontSize: 13 }}>{validateErr && validateErr.contact}</p>}
                                </div>
                                <div className="formInput">
                                    <label >
                                        Password
                                    </label>

                                    <input
                                        name="password"
                                        defaultValue={formValues.password}
                                        type="password"
                                        placeholder="password"
                                        onChange={handleChange}
                                    />
                                    {errSatus === true && formValues && formValues.password === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.passwordErr}</p>}

                                </div>
                            </div>
                            {/* {error && <p style={{ color: "#96332c", fontSize: 13 }}> {error.message}</p>} */}
                            <button onClick={submitData} className="btn btn-submit">{resourceid ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                        : ""}

                </div>

            </div>
        </div>

    )

}

export default CandidateEdit