import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { QuestionSetColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, ArrowBack, Close } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields, InputSelectField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import { getQuestionSetList, questionSetArchive, questionSetCreate, questionSetEdit, questionSetUpdate } from "../../redux/features/masterSlice";
import { questionLevelListOption, technologyListOption } from "../../redux/features/optionsSlice";
import Filter from "../../components/Filter";
import Select from 'react-select';
import { timeHoursConvert, timeMinutConvert } from "../../components/util/CalculationTime";


const initialFormData = Object.freeze({
    set_name: "",
    technology: "",
    level: "",
    no_of_question: "",
    maximum_time: "",
    status: "",
    question_type_details: { mcq: '', programming: '' }
});

const formErrData = Object.freeze({
    setNameErr: "Set name is required",
    technologyErr: "Technology is required",
    levelErr: "Question level is required",
    noOfQuestionErr: "Number of question is required",
    maximumTimeErr: "Test duration is required",
    statusErr: "Status is required"
});

var hoursList = [];
for (var i = 0; i < 24; i++) {
    hoursList.push({ value: i, label: i });
}

var minuteList = [{ value: 0, label: "0" }];
let gap = 5;
for (let i = 1; i <= 11; ++i) {
    minuteList.push({ value: i * gap, label: i * gap });
}

const QuestionSet = () => {
    const { REACT_APP_ENV } = process.env;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, questionset_List, questionset_Create, questionset_Edit, questionset_Update, questionset_Archive } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":10}`)
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const { technology, questionlevel } = useSelector(state => state.dropDownOption)

    const [filterTechnologyList, setFilterTechnologyList] = useState([]);
    const [filterQuestionLevelList, setFilterQuestionLevelList] = useState([]);
    // custom filter
    const [fieldName, setFieldName] = useState("")
    const [options, setOptions] = useState({})
    const [defaultValue, setDefaultValue] = useState({})

    const [noOfMcqQuestion, setNoOfMcqQuestion] = useState('');
    const [noOfProgrammingQuestion, setNOfProgrammingQuestion] = useState('');
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);

    if (filterTechnologyList && filterTechnologyList.length === 0 && technology) {
        let array = []
        technology && technology.map(e => { return array.push({ value: e.id, label: e.name }) })
        array.length > 0 && setFilterTechnologyList(array)
        array.length > 0 && setOptions({ ...options, technology_id: array })
    }

    if (filterQuestionLevelList && filterQuestionLevelList.length === 0 && questionlevel) {
        let array = []
        questionlevel && questionlevel.map(e => { return array.push({ value: e.id, label: e.level_name }) })
        array.length > 0 && setFilterQuestionLevelList(array)
        array.length > 0 && setOptions({ ...options, level_id: array })
    }

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
    }, [error])

    useEffect(() => {
        dispatch(getQuestionSetList(filterQuery))
        dispatch(questionLevelListOption())
        dispatch(technologyListOption())
    }, [])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0) }
        setOpen(false);
        setFormValues(initialFormData);
        setErrStatus(false)
        setNoOfMcqQuestion('')
        setNOfProgrammingQuestion('')
        setMinutes(0)
        setHours(0)
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_questionset_Update` && questionset_Update && questionset_Update.message) {
        if (questionset_Update.status === 200) {
            toast.success(questionset_Update.message)
            dispatch(getQuestionSetList(filterQuery))
            handleClose()
        } else {
            toast.error(questionset_Update.message)
        }
        setReload(false)
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_questionset_Create` && questionset_Create && questionset_Create.message) {
        if (questionset_Create.status === 200) {
            toast.success(questionset_Create.message)
            dispatch(getQuestionSetList(filterQuery))
            handleClose()
        } else {
            toast.error(questionset_Create.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_questionset_Archive` && questionset_Archive && questionset_Archive.message) {
        if (questionset_Archive.status === 200) {
            toast.success(questionset_Archive.message)
            dispatch(getQuestionSetList(filterQuery))
        } else {
            toast.error(questionset_Archive.message)
        }
        setReload(false)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
    }

    const submitData = (e) => {
        e.preventDefault()
        if (formValues.set_name === "" || formValues.technology === "" || formValues.level === "" || (hours === 0 && minutes === 0)
            || (noOfMcqQuestion === "" && noOfProgrammingQuestion === "") || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.set_name !== "" && formValues.technology !== "" && formValues.level !== ""
            && formValues.status !== "" && (hours !== 0 || minutes !== 0) && (noOfMcqQuestion !== "" || noOfProgrammingQuestion !== "")) {
            const ces_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`))
            let userId = ces_user.user && ces_user.user.data && ces_user.user.data.id
            setErrStatus(false)
            setReload("submit")
            let hours_value = hours && hours.value ? parseInt(hours.value) : parseInt(hours)
            // let min_value = minutes && minutes.value ? parseInt(minutes.value) : parseInt(minutes)
            let mcq = noOfMcqQuestion ? parseInt(noOfMcqQuestion) : 0
            let programming = noOfProgrammingQuestion ? parseInt(noOfProgrammingQuestion) : 0

            let min_value;
            if (ID) {
                if (minutes && minutes.value) min_value = parseInt(minutes.value)
                else if (minutes > 0 && !minutes.value) min_value = parseInt(minutes)
                else if (minutes === 0 && !minutes.value) min_value = minutes
                else min_value = parseInt(minutes.value)
            } else {
                min_value = minutes && minutes.value ? parseInt(minutes.value) : parseInt(minutes)
            }
            const total_time = parseInt(hours_value) > 0 ? hours_value * 60 + min_value : min_value

            const addvalues = ({
                ...formValues,
                no_of_question: mcq + programming,
                question_type_details: { mcq: noOfMcqQuestion, programming: noOfProgrammingQuestion },
                maximum_time: total_time,
                technology: formValues.technology && formValues.technology.value ? formValues.technology.value : formValues.technology,
                level: formValues.level && formValues.level.value ? formValues.level.value : formValues.level,
                updated_by: userId
            })
            if (ID) {
                dispatch(questionSetUpdate({ ID, addvalues }))
            } else {
                dispatch(questionSetCreate(addvalues))
            }
        }
    }

    const selectValueTechnologyLable = (id) => {
        let data = technology && technology;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.name, name: "technology" })
        })
        return newObject
    }

    const selectValueQuestionLevelLable = (id) => {
        let data = questionlevel && questionlevel;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.level_name, name: "level" })
        })
        return newObject
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && questionset_Edit && questionset_Edit.data) {
        let editData = questionset_Edit.data
        setFormValues({
            set_name: editData.set_name,
            technology: selectValueTechnologyLable(editData.technology_id),
            level: selectValueQuestionLevelLable(editData.level_id),
            no_of_question: editData.no_of_question,
            maximum_time: editData.maximum_time,
            question_type_details: editData.question_type_details,
            status: editData.status
        })
        let hoursTime = timeHoursConvert(editData.maximum_time)
        let minTime = timeMinutConvert(editData.maximum_time)
        setHours({ label: hoursTime, value: hoursTime })
        setMinutes({ label: minTime, value: minTime })

        if (editData && editData.question_type_details) {
            editData.question_type_details.mcq && setNoOfMcqQuestion(editData.question_type_details.mcq)
            editData.question_type_details.programming && setNOfProgrammingQuestion(editData.question_type_details.programming)
        } else {
            setNoOfMcqQuestion('')
            setNOfProgrammingQuestion('')
        }
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(questionSetEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
    }

    const handleClickArchive = (id) => {
        setReload(true)
        setArchiveOpen(false)
        dispatch(questionSetArchive(id))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getQuestionSetList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getQuestionSetList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(questionset_List && questionset_List.data && questionset_List.data.rows && questionset_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getQuestionSetList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
        }
    }

    const actionColumn = [
        {
            field: "technology_id", headerName: "Technology", width: 200,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.technology}
                    </div>
                );
            },
        },
        {
            field: "level_id", headerName: "Question Level", width: 190,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.level}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 150,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="question-level"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={handleArchive} />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getQuestionSetList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getQuestionSetList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
            setDefaultValue("")
        }
    }

    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            <Filter options={options[fieldName]} onChange={handleFilterChange} defaultValue={defaultValue} />
        );
    }

    const columns = React.useMemo(() =>
        actionColumn.map((col) => {
            if (col.field === "technology_id" || col.field === "level_id") {
                return {
                    ...col,
                    filterOperators: getGridSingleSelectOperators()
                        .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                        .map((operator) => ({
                            ...operator,
                            InputComponent: operator.InputComponent
                                ? CustomFilterInputValue
                                : undefined
                        }))
                };
            }
            return col;
        }),
        [actionColumn]
    );

    return (
        <div className="master">
            <Sidebar />
            <div className="masterContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Set List</h3>
                        <div>
                            <Tooltip title="Add New Set">
                                <button onClick={handleOpen} className="btn-add-new"><Add /> </button>
                            </Tooltip>
                            <Tooltip title="Back">
                                <Link to={-1} className="btn-add-new"><ArrowBack /> </Link>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={questionset_List && questionset_List.data && questionset_List.data.rows ? questionset_List.data.rows : []}
                        columns={QuestionSetColumns.concat(columns)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "10px" }}>{ID ? 'Edit' : 'Add New'} Set</DialogTitle>

                    <DialogContent style={{ height: "700px" }}>
                        <form>
                            <div className="add-new-master-form">
                                <InputFields
                                    label={"Set Name"}
                                    name="set_name"
                                    defaultValue={formValues.set_name}
                                    type="text"
                                    placeholder="Set Name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.setNameErr}
                                />
                                <InputSelectField
                                    label={'Technology'}
                                    placeholder="Select Technology"
                                    defaultValue={formValues.technology}
                                    name={"technology"}
                                    onChange={handleChange}
                                    optionList={technology ? technology : []}
                                    optionLevel={['name']}
                                    optionValue={"id"}
                                    errStatus={errStatus}
                                    formErr={formErr.technologyErr}
                                />
                                <div className="formInput" style={{ flexBasis: "100%" }}>
                                    <InputSelectField
                                        label={'Question Level'}
                                        placeholder="Select Question Level"
                                        defaultValue={formValues.level}
                                        name={"level"}
                                        onChange={handleChange}
                                        optionList={questionlevel ? questionlevel : []}
                                        optionLevel={['level_name']}
                                        optionValue={"id"}
                                        errStatus={errStatus}
                                        formErr={formErr.levelErr}
                                    />
                                </div>
                                <div className="formInput">
                                    <label>Number Of MCQ Question</label>
                                    <input
                                        name="no_of_mcq_question"
                                        value={noOfMcqQuestion}
                                        type="number"
                                        placeholder="Number Of MCQ Question"
                                        onChange={(e) => setNoOfMcqQuestion(e.target.value)}
                                        min="1"
                                    />
                                </div>
                                <div className="formInput">
                                    <label>Number Of Programming Question</label>
                                    <input
                                        name="no_of_programming_question"
                                        value={noOfProgrammingQuestion}
                                        type="number"
                                        placeholder="Number Of Programming Question"
                                        onChange={(e) => setNOfProgrammingQuestion(e.target.value)}
                                        min="1"
                                    />
                                </div>

                                <div className="formInput" style={{ flexBasis: "100%" }} >
                                    {errStatus === true && formValues && (noOfMcqQuestion === "" && noOfProgrammingQuestion === "") && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.noOfQuestionErr}</p>}
                                </div>

                                <div className="formInput" style={{ flexBasis: "100%" }} >
                                    Test Duration
                                </div>
                                <div className="formInput">
                                    <label>Select Hours</label>
                                    <Select
                                        className="select"
                                        placeholder="Select Hours"
                                        defaultValue={hours}
                                        name={"level"}
                                        onChange={(e) => setHours(e.value)}
                                        options={hoursList}
                                    />
                                </div>
                                <div className="formInput">
                                    <label>Select Minutes</label>
                                    <Select
                                        className="select"
                                        placeholder="Select Minutes"
                                        defaultValue={minutes}
                                        name={"level"}
                                        onChange={(e) => setMinutes(e.value)}
                                        options={minuteList}
                                    />
                                </div>
                                <div className="formInput" style={{ flexBasis: "100%" }} >
                                    {errStatus === true && formValues && (hours === 0 && minutes === 0) && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.maximumTimeErr}</p>}
                                </div>
                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />
                            </div>
                            <button
                                className={reload === "submit" ? "submit-btn-disabled" : "submit-modal"}
                                onClick={submitData}
                                disabled={reload === "submit" ? true : false}
                            >
                                {ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                            </button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={'Archive'}
                    paragraph={'Are you sure to archive question set'}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default QuestionSet