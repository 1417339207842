const { REACT_APP_ENV } = process.env;
class LocalStorageDataService {

    userDetail() {
        return localStorage.getItem(`${REACT_APP_ENV}_CES`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`)).user.data : "";
    }

    userToken() {
        return localStorage.getItem(`${REACT_APP_ENV}_CES`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`)).token : "";
    }

    lastInteractionTime() {
        return localStorage.getItem(`${REACT_APP_ENV}_CES`) ? JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`)).lastInteractionTime : "";
    }

}
export default new LocalStorageDataService();