import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar"
import { toast, ToastContainer } from "react-toastify";
import { TablePagination, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import * as React from 'react';
import { Button } from '@mui/material';
import LoaderForm from "../../components/common-function/LoaderForm";
import TablePaginationActions from "../../components/TablePaginationActions";
import { ArrowBack } from "@mui/icons-material";
import { getCandidateExamScheduleList } from "../../redux/features/candidateExamSlice";
import { questionLevelListOption, questionTypeListOption, setListOption, technologyListOption } from "../../redux/features/optionsSlice";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import moment from "moment";


const ExamScheduledList = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { questionlevel_List } = useSelector(state => state.master)
    const { questionlevels, technologys, sets } = useSelector(state => state.dropDownOption)
    const { status, candidateExamScheduleList } = useSelector(state => state.candidate)
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"filter":{"candidate_id":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":10}`)
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        dispatch(getCandidateExamScheduleList(filterQuery))
        dispatch(technologyListOption())
        dispatch(questionTypeListOption())
        dispatch(questionLevelListOption())
        dispatch(setListOption())
    }, [])


    if (loading === true && status === `${STATUSES.SUCCESS}_candidateExamScheduleList`) {
        if (candidateExamScheduleList.status === 200) {
        } else {
            toast.error(candidateExamScheduleList.message)
        }
        setLoading(false)
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCandidateExamScheduleList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCandidateExamScheduleList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(questionlevel_List && questionlevel_List.data && questionlevel_List.data.rows && questionlevel_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getCandidateExamScheduleList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{"filter":{"candidate_id":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"}}, "skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
        }
    }

    const startTest = (data) => {
        //dispatch(setCandidateSelectedExam(data));
        
        navigate('/term-and-condition', { state: { data: data } })
    }


    const actionColumn = [
        {
            field: "candidate_id", headerName: "Candidate Name", width: 230, filterable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.candidate.first_name} {params.row.candidate.last_name}
                    </div>
                );
            }
        },
        {
            field: "set_id", headerName: "Set", width: 230, type: 'singleSelect', valueOptions: sets, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.set ? params.row.set.set_name : "-"}
                    </div>
                );
            },
        },
        {
            field: "technology_id", headerName: "Technology", width: 230, type: 'singleSelect', valueOptions: technologys, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.technology ? params.row.technology.name : "-"}
                    </div>
                );
            },
        },
        {
            field: "question_level_id", headerName: "Level", width: 230, type: 'singleSelect', valueOptions: questionlevels, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.question_level ? params.row.question_level.level_name : "-"}
                    </div>
                );
            },
        },
        {
            field: "scheduled_date", headerName: "Scheduled Date", width: 200, filterable: false, renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {moment(params.row.scheduled_date).format('DD-MM-YYYY')}
                    </div>
                );
            },
        },
        { field: "scheduled_time", headerName: "Scheduled Time", width: 200, filterable: false, },

        {
            field: "status",
            headerName: "Status",
            width: 160,
            type: 'singleSelect',
            valueOptions: [{ value: 'True', label: 'Active' }, { value: 'False', label: 'Inactive' }],
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {                
                var beginningDateTime = moment(params.row.scheduled_date+' '+params.row.scheduled_time, 'YYYY-MM-DD h:mma');
                var currentDateTime = moment(moment().format('YYYY-MM-DD H:m:s'), 'YYYY-MM-DD h:mma');                
                return (
                    <div className="cellAction">
                        {(params.row.schedule_status === 1 || params.row.schedule_status === 0) &&  beginningDateTime.isSameOrBefore(currentDateTime) ?
                            <Button style={{ backgroundColor: '#0052cc', color: '#fff' }} onClick={() => startTest(params.row)}>Take Test</Button>
                            :
                            <Button style={params.row.schedule_status === 3 ? { backgroundColor: 'green', color: '#fff' } : { backgroundColor: '#544d4d', color: '#fff' }} disabled>{params.row.schedule_status === 3 ? "Completed": params.row.schedule_status === 2 ?"Inprogress"  :"Take Test"}</Button>

                        }
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "is" ? 'eq' :
                    filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}, "candidate_id":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getCandidateExamScheduleList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"filter":{"candidate_id":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getCandidateExamScheduleList(`{"filter":{"candidate_id":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"}},"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
        }
    }
    return (
        <div className="master">
            <Sidebar />
            <div className="masterContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>My Test List</h3>
                        <div>
                            <Tooltip title="Back">
                                <Link to={-1} className="btn-add-new"><ArrowBack /> </Link>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || loading === true) && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={candidateExamScheduleList && candidateExamScheduleList.data && candidateExamScheduleList.data.rows ? candidateExamScheduleList.data.rows : []}
                        columns={actionColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>
            </div>
        </div >
    )
}

export default ExamScheduledList