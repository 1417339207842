import { Tooltip } from "@mui/material";
import moment from "moment";

//columns heading for department
export const departmentColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "name", headerName: "Department Name", width: 230 },
  { field: "code", headerName: "Code", width: 230 },
  { field: "description", headerName: "Description", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

//columns heading for role
export const roleColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "role_name", headerName: "Role Name", width: 230 },
  { field: "role_key", headerName: "Role Key", width: 230 },
  { field: "role_description", headerName: "Description", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];


//columns heading for user type
export const userTypeColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "type_name", headerName: "User Type Name", width: 230 },
  { field: "type_key", headerName: "User Type Key", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];


//columns heading for Menu List
export const menuListColumn = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "title", headerName: "Title", width: 230 },
  { field: "url_slug", headerName: "Slug", width: 230 },
  { field: "description", headerName: "Description", width: 230 },
  {
    field: "roles",
    headerName: "Permission",
    width: 300,
    filterable: false,
    renderCell: (params) => {
      let list = params.row.roles && params.row.roles.length > 0 ? params.row.roles : []
      var resArr = [];
      list.length > 0 && list.filter(function (item) {
        var i = resArr.findIndex(x => (x.role_id === item.role_id && x.menu_id === item && x.status === item.status));
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });
      return (
        <div className={`cellWithStatus ellips-css-data`}>
          {resArr.length > 0 && resArr.map((e, index) => `${index ? ', ' : ''}${(e.role_name).trim()}`)}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

//columns heading for third party
export const ThirdPartyColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "name", headerName: "Name", width: 230 },
  { field: "code", headerName: "Code", width: 230 },
  { field: "description", headerName: "Description", width: 300 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];


export const QuestionTypeColumns = [
  // { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "question_type", headerName: "Question Type", width: 240 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const TechnologyColumns = [
  // { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "name", headerName: "Technology Name", width: 230 },
  { field: "tech_type", headerName: "Technology Type", width: 230 },
  { field: "description", headerName: "Description", width: 300 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const questionLevelColumns = [
  // { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "level_name", headerName: "Question Level Name", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const QuestionSetColumns = [
  { field: "set_name", headerName: "Set Name", width: 190 },
  { field: "no_of_question", headerName: "No Of Question", width: 170 },
  { field: "maximum_time", headerName: "Maximum Time (in min)", width: 180 },
];

export const QuestionColumns = [
  {
    field: "title", headerName: "Title", width: 200, renderCell: (params) => {
      return (
        <Tooltip title={params.row.title}>
          <div className="cellWithStatus">
            {params.row.title}
          </div>
        </Tooltip>
      );
    },
  },
  {
    field: "description", headerName: "Description", width: 200, renderCell: (params) => {
      return (
        <Tooltip title={params.row.description}>
          <div className="cellWithStatus">
            {params.row.description}
          </div>
        </Tooltip>
      );
    },
  },
  { field: "marks", headerName: "Marks", width: 90 },
];
