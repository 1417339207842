import axios from "axios";

const { REACT_APP_API_BASEURL, REACT_APP_ENV } = process.env;
const ces = localStorage.getItem(`${REACT_APP_ENV}_CES`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`))

export default axios.create({
  baseURL: REACT_APP_API_BASEURL,
  headers: {
    "Content-type": "application/json",
    "Authorization": ces ? ces.token : '',
    // "Cookie":`token=${localStorage.getItem('token')}`
  }
});
