
import Login from "./pages/login/Login";
import DashboardMenu from "./pages/dashboard/DashboardMenu";
import "./style/dark.scss";
import "./App.css"
import { PrivateRoute } from "./redux/helpers/PrivateRoute";
import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import ResetPassword from "./pages/reset-password/ResetPassword";
import Profile from "./pages/profile/Profile";
import EditProfile from "./pages/edit-profile/EditProfile";
import Setting from "./pages/setting/Setting";
import Notification from "./pages/notification/Notification";
import Messages from "./pages/message/Message";
import AllManageUser from "./pages/manage-user/AllUser";
import AddManageUser from "./pages/manage-user/AddUser";
import AllRoles from "./pages/manage-roles/AllRoles";
import AddRole from "./pages/manage-roles/AddRole";
import ResourcesList from "./pages/resources/ResoucesList";
import ResourceView from "./pages/resources/ResourceView";
import ResourceEdit from "./pages/resources/ResourceEdit";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import LocalStorageDataService from "./components/common-function/GetLocalstorage";
import { getAsigneRoleMenuList } from "./redux/features/masterSlice";
import Technology from "./pages/master/Technology";
import QuestionLevel from "./pages/master/QuestionLevel";
import QuestionType from "./pages/master/QuestionType";
import Stepform from "./pages/attemp-test/Stepform";
import Stepformnew from "./pages/attemp-test/Stepformnew";
import CandidateList from "./pages/candidate/CandidateList";
import CandidateExamSchdule from "./pages/CandidateExamSchdule";
import CandidateEdit from "./pages/candidate/CandidateEdit";
import CandidateView from "./pages/candidate/CandidateView";
import QuestionSet from "./pages/master/QuestionSet";
import Question from "./pages/master/Question";
import Error404 from "./pages/error-pages/Error404";
import TermandCondition from "./pages/term-and-condition/TermandCondition";
import ExamScheduledList from "./pages/candidate-exam-process/ExamScheduledList";
import CandidateKyc from "./pages/candidate-kyc/CandidateKyc";
import TestCandidateForm from "./pages/attemp-test/TestCandidateForm";


const user = LocalStorageDataService.userDetail();
const tmsRole = user && user.tms_role_id
const roleWiseFilter = tmsRole && `{"filter":{"status":{"value":"True","operation": "eq"},"role_id":{"value":${tmsRole},"operation": "eq"}},"no_limit":True}`

const { REACT_APP_ENV } = process.env;

function App() {

  // Acl Router
  const dispatch = useDispatch()
  useEffect(() => {
    const auth = localStorage.getItem(`${REACT_APP_ENV}_CES`) ? true : false;
    auth && dispatch(getAsigneRoleMenuList(roleWiseFilter))
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Error404 />} />

        <Route exact path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
        <Route
          exact path="/dashboard"
          element={
            <PrivateRoute path="dashboard?reload=done">
              <DashboardMenu />
            </PrivateRoute>
          }
        />


        <Route path="/profile" element={<PrivateRoute path="profile"><Profile /></PrivateRoute>} />
        <Route path="/profile/:Id" element={<PrivateRoute path="profile"><Profile /></PrivateRoute>} />
        <Route path="/edit-profile" element={<PrivateRoute path="edit-profile"><EditProfile title="Edit Profile" /></PrivateRoute>} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/all-massages" element={<Messages />} />
        <Route path="/test" element={<Stepform />} />
        <Route path="/testnew" element={<Stepformnew />} />

        {/* User Type */}

        <Route path="/all-user" element={
          <PrivateRoute path="all-user"><AllManageUser /></PrivateRoute>
        } />

        <Route path="/add-new-user" element={
          <PrivateRoute path="add-new-user"><AddManageUser title="Add New User-type" /></PrivateRoute>
        } />
        <Route path="/edit-user/:id" element={
          <PrivateRoute path="edit-user">
            <AddManageUser title="Edit User-type " />
          </PrivateRoute>
        } />


        {/* roles */}
        <Route path="/roles" element={<PrivateRoute path="roles"><AllRoles /></PrivateRoute>} />
        <Route path="/add-new-role" element={
          <PrivateRoute path="add-new-role">
            <AddRole title="Add New Role" />
          </PrivateRoute>
        } />
        <Route path="/edit-role/:id" element={
          <PrivateRoute path="edit-role">
            <AddRole title="Edit Role" />
          </PrivateRoute>
        } />

        {/* Users  Route */}
        <Route path="/users">
          <Route index element={<PrivateRoute path="users"><ResourcesList /></PrivateRoute>} />
          <Route path="edit/:resourceid" element={<PrivateRoute path="users/edit"><ResourceEdit /></PrivateRoute>} />
          <Route path="add" element={<PrivateRoute path="users/add"><ResourceEdit /></PrivateRoute>} />
          <Route path="view/:resourceid" element={<PrivateRoute path="users/view"><ResourceView /></PrivateRoute>} />
        </Route>

        {/* Users  Route */}
        <Route path="/candidate">
          <Route index element={<PrivateRoute path="candidate"><CandidateList /></PrivateRoute>} />
          <Route path="edit/:resourceid" element={<PrivateRoute path="candidate/edit"><CandidateEdit /></PrivateRoute>} />
          <Route path="add" element={<PrivateRoute path="candidate/add"><CandidateEdit /></PrivateRoute>} />
          <Route path="view/:resourceid" element={<PrivateRoute path="candidate/view"><CandidateView /></PrivateRoute>} />
        </Route>

        {/* all master routing */}
        <Route path="/technology" element={<PrivateRoute path="technology"><Technology /></PrivateRoute>} />
        <Route path="/question-level" element={<PrivateRoute path="question-level"><QuestionLevel /></PrivateRoute>} />
        <Route path="/question-type" element={<PrivateRoute path="question-type"><QuestionType /></PrivateRoute>} />
        <Route path="/sets" element={<PrivateRoute path="set"><QuestionSet /></PrivateRoute>} />
        <Route path="/questions" element={<PrivateRoute path="question"><Question /></PrivateRoute>} />
        <Route path="/candidate_exam_schedule" element={<PrivateRoute path="candidate_exam_schedule"><CandidateExamSchdule /></PrivateRoute>} />
        <Route path="/term-and-condition" element={<PrivateRoute path="term-and-condition"><TermandCondition /></PrivateRoute>} />
        <Route path="/candidate-kyc" element={<PrivateRoute path="candidate-kyc"><CandidateKyc /></PrivateRoute>} />
        <Route path="/my-test" element={<PrivateRoute path="my-test"><ExamScheduledList /></PrivateRoute>} />
        <Route path="/candidate-test" element={<PrivateRoute path="my-test"><TestCandidateForm /></PrivateRoute>} />



      </Routes>

    </BrowserRouter>
  );
}

export default App;
