import http from "../APIS/api";
//import formdata_api from "../APIS/formdata_api";
import LocalStorageDataService from '../../components/common-function/GetLocalstorage'

class DataService {

  create(data) {
    return http.post("/login", data);
  }

  userLogin(token) {
    return http.get("/user", {
      headers: {
        'Authorization': token,
        //'Cookie':`token=${token}`
      }
    });
  }

  logout() {
    return http.post("/logout");
  }

  //Department api
  departmentList(filterQuery) {
    return http.get(`/mst/departments?where=${filterQuery}`);
  }

  department_List(filterQuery) {
    return filterQuery ? http.get(`/mst/departments?where=${JSON.stringify(filterQuery)}`)
      :
      // http.get('/mst/departments?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":"True"}')
      http.get('/mst/departments?where={"filter":{"status":{"value":"True","operation":"eq"}} ,"no_limit":"True"}')

  }

  addDepartment(data) {
    return http.post("/mst/departments", data);
  }

  departmentById(id) {
    return http.get(`/mst/departments/${id}`);
  }

  updateDepartment(id, data) {
    return http.put(`/mst/departments/${id}`, data);
  }

  deleteDepartment(id, data) {
    return http.delete(`/mst/departments/${id}`, data);
  }


  //role api
  roleList(filterQuery) {
    return http.get(`/mst/tms-roles?where=${filterQuery}`);
  }

  addRole(data) {
    return http.post("/mst/tms-roles", data);
  }

  roleById(id) {
    return http.get(`/mst/tms-roles/${id}`);
  }

  updateRole(id, data) {
    return http.put(`/mst/tms-roles/${id}`, data);
  }

  deleteRole(id, data) {
    return http.delete(`/mst/tms-roles/${id}`, data);
  }

  //user Type api
  userTypeList(filterQuery) {
    return http.get(`mst/user-type?where=${filterQuery}`);
  }

  addUserType(data) {
    return http.post("/mst/user-type ", data);
  }

  userTypeById(id) {
    return http.get(`/mst/user-type/${id}`);
  }

  updateUserType(id, data) {
    return http.put(`/mst/user-type/${id}`, data);
  }

  deleteUserType(id, data) {
    return http.delete(`/mst/user-type/${id}`, data);
  }

  country_list() {
    return http.get('mst/country')
  }

  ///resource list

  resource_list(filterQuery) {
    if (filterQuery) {
      return http.get(`evaluation/users/list?where=${filterQuery}`)
    } else {
      let user = LocalStorageDataService.userDetail();
      let tmsRole = user && user.tms_role
      if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
        return http.get('/evaluation/users/list?where={"order_by":{"column":"name"},"no_limit":True}')
      } else {
        return http.get(`/evaluation/users/list?where={"filter":{"id":{"value":${user.id},"operation": "eq"}}, "order_by":{"column":"name"},"no_limit":True}`)
      }
    }
  }

  candidate_list(filterQuery) {
    if (filterQuery) {
      return http.get(`evaluation/candidate-registration?where=${filterQuery}`)
    } else {
      let user = LocalStorageDataService.userDetail();
      let tmsRole = user && user.tms_role
      if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
        return http.get('/evaluation/candidate-registration?where={"order_by":{"column":"name"},"no_limit":True}')
      } else {
        return http.get(`/evaluation/candidate-registration?where={"filter":{"id":{"value":${user.id},"operation": "eq"}}, "order_by":{"column":"name"},"no_limit":True}`)
      }
    }
  }

  get_all_resource_list() {
    return http.get('/evaluation/users/list?where={"filter":{"status":{"value":"True","operation": "eq"}},"no_limit":True}')
  }

  get_all_candidate_list() {
    return http.get('/evaluation/candidate-registration?where={"filter":{"status":{"value":"True","operation": "eq"}},"no_limit":True}')
  }

  ///resource view by id

  resource_view(id) {
    return http.get(`/evaluation/candidate-registration/${id}`);
  }

  candidate_view(id) {
    return http.get(`/evaluation/candidate-registration/${id}`);
  }

  ///resource add

  resource_add(data) {
    return http.post("/evaluation/candidate-registration", data);
  }

  ///resource edit
  resource_edit(id, data) {
    return http.put(`/evaluation/candidate-registration/${id}`, data);
  }

  ///resource delete

  resource_delete(id, data) {
    return http.delete(`/evaluation/candidate-registration/${id}`, data);
  }

  candidate_delete(id, data) {
    return http.delete(`/evaluation/candidate-registration/${id}`, data);
  }


  //all  role lis 
  all_role_list() {
    return http.get(`/mst/tms-roles?where={"no_limit":True}`);
  }

  // all depattment list

  all_department_list() {
    return http.get(`/mst/departments?where={"no_limit":True}`);
  }
  department_type_list() {
    return http.get(`/mst/departments?where={"no_limit":True}`);
  }

  tms_role_list(id) {
    return http.get(`/mst/tms-roles?where={"no_limit":True}`);
  }


  create_resources(data) {
    return http.post(`/evaluation/candidate-registration`, data);
  }
  create_candidate(data) {
    return http.post(`/evaluation/candidate-registration`, data);
  }

  update_resources(id, data) {
    return http.put(`/evaluation/candidate-registration/${id}`, data);
  }

  update_candidate(id, data) {
    return http.put(`/evaluation/candidate-registration/${id}`, data);
  }

  import_file_candidate(data) {
    return http.post(`/evaluation/candidate/import`, data);
  }

  download_samplefile_candidate() {
    return http.get(`/evaluation/candidate/download/sample`);
  }

  // ________ Question set Start_______________//
  all_questionSet_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/set?where=${filterQuery}`);
    } else {
      return http.get(`/mst/set?where={ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True }`);
    }
  }
  questionSet_list(filterQuery) {
    return http.get(`/mst/set?where=${filterQuery}`);
  }
  questionSet_create(data) {
    return http.post(`/mst/set`, data);
  }
  questionSet_edit(id) {
    return http.get(`/mst/set/${id}`);
  }
  questionSet_update(id, data) {
    return http.put(`/mst/set/${id}`, data);
  }
  questionSet_archive(id, data) {
    return http.delete(`/mst/set/${id}`, data);
  }
  // ________Question Set End_________________//

  // ________question-type Start_______________//
  all_QuestionType_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/question-type?where=${filterQuery}`);
    } else {
      return http.get(`/mst/question-type?where={"filter":{"status":{"value":"True","operation": "eq"}}, "no_limit":True}`);
    }
  }
  questionType_list(filterQuery) {
    return http.get(`/mst/question-type?where=${filterQuery}`);
  }
  questionType_create(data) {
    return http.post(`/mst/question-type`, data);
  }
  questionType_edit(id) {
    return http.get(`/mst/question-type/${id}`);
  }
  questionType_update(id, data) {
    return http.put(`/mst/question-type/${id}`, data);
  }
  questionType_archive(id, data) {
    return http.delete(`/mst/question-type/${id}`, data);
  }
  // ________ question-type End_________________//

  // ________technology Start_______________//
  all_technology_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/technology?where=${filterQuery}`);
    } else {
      return http.get(`/mst/technology?where={"filter":{"status":{"value":"True","operation": "eq"}}, "no_limit":True}`);
    }
  }
  technology_list(filterQuery) {
    return http.get(`/mst/technology?where=${filterQuery}`);
  }
  technology_create(data) {
    return http.post(`/mst/technology`, data);
  }
  technology_edit(id) {
    return http.get(`/mst/technology/${id}`);
  }
  technology_update(id, data) {
    return http.put(`/mst/technology/${id}`, data);
  }
  technology_archive(id, data) {
    return http.delete(`/mst/technology/${id}`, data);
  }
  // ________technology End_________________//

  // _________QuestionLevel Start_______________//
  all_questionlevel_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/question-level?where=${filterQuery}`);
    } else {
      return http.get(`/mst/question-level?where={"filter":{"status":{"value":"True","operation": "eq"}}, "no_limit":True}`);
    }
  }
  questionlevel_list(filterQuery) {
    return http.get(`/mst/question-level?where=${filterQuery}`);
  }
  questionlevel_create(data) {
    return http.post(`/mst/question-level`, data);
  }
  questionlevel_edit(id) {
    return http.get(`/mst/question-level/${id}`);
  }
  questionlevel_update(id, data) {
    return http.put(`/mst/question-level/${id}`, data);
  }
  questionlevel_archive(id, data) {
    return http.delete(`/mst/question-level/${id}`, data);
  }
  // ________questionlevel End_________________//

  // _________Question Start_______________//
  all_question_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/evaluation/questions?where=${filterQuery}`);
    } else {
      return http.get(`/mst/evaluation/questions?where={"filter":{"status":{"value":"True","operation": "eq"}}, "no_limit":True}`);
    }
  }
  question_list(filterQuery) {
    return http.get(`/evaluation/questions?where=${filterQuery}`);
  }
  question_create(data) {
    return http.post(`/evaluation/questions`, data);
  }
  question_edit(id) {
    return http.get(`/evaluation/questions/${id}`);
  }
  question_update(id, data) {
    return http.put(`/evaluation/questions/${id}`, data);
  }
  question_archive(id, data) {
    return http.delete(`/evaluation/questions/${id}`, data);
  }

  import_file_question(data) {
    return http.post(`/evaluation/questions/import`, data);
  }

  download_samplefile_question() {
    return http.get(`/evaluation/questions/download/sample`);
  }
  // ________question End_________________//

  //__________answer start____________//

  answer_option_list(filterQuery) {
    return http.get(`/evaluation/answer-option?where=${filterQuery}`);
  }
  answer_option_create(data) {
    return http.post(`/evaluation/answer-option`, data);
  }
  answer_option_update(id, data) {
    return http.put(`/evaluation/answer-option/${id}`, data);
  }
  answer_option_delete(id, data) {
    return http.delete(`/evaluation/answer-option/${id}`, data);
  }

  //__________answer end_______________//

  //___________ candidate Start___________//
  candidate_exam_schedule_list(filterQuery) {
    return http.get(`/evaluation/candidate/exam-schedule?where=${filterQuery}`);
  }
  candidate_exam_schedule_create(data) {
    return http.post(`/evaluation/candidate/exam-schedule`, data);
  }
  candidate_exam_schedule_edit(id) {
    return http.get(`/evaluation/candidate/exam-schedule/${id}`);
  }
  candidate_exam_schedule_update(id, data) {
    return http.put(`/evaluation/candidate/exam-schedule/${id}`, data);
  }
  candidate_exam_schedule_archive(id, data) {
    return http.delete(`/evaluation/candidate/exam-schedule/${id}`, data);
  }
  //___________candidate end _____________//

  //___________Exam Question start_______//

  
  get_exam_questions(schedule_id) {
    return http.get(`/evaluation/questions/get-questions/${schedule_id}`);
  }

  get_exam_schedule_status(schedule_id, data){
    return http.put(`/evaluation/candidate/exam-schedule-status/${schedule_id}`, {schedule_status:data});
  }

  exam_compile_post(data){
    return http.post(`/evaluation/program-evalutions`, data);
  }

  exam_compile_update(id, data){
    return http.put(`/evaluation/program-evalutions/${id}`, data);
  }

  exam_post_submit(data){
    return http.post(`/evaluation/candidate/exam-data`, data);
  }

  //___________Exam Question End_________//



}


export default new DataService();