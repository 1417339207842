import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../redux/common-status/constant";
import CONSTANTS from "../components/constant/constantComponents";
import * as React from 'react';
import { DialogTitle, Button } from '@mui/material';
import LoaderForm from "../components/common-function/LoaderForm";
import UcActionComponent from "../components/common-function/UcActionComponent";
import TablePaginationActions from "../components/TablePaginationActions";
import { Add, ArrowBack, Close } from "@mui/icons-material";
import { InputSelectValueField, SingleDatePickerInputField, SingleTimePickerInputField } from "../components/common-function/InputFields";
import DeleteDialogModal from "../components/common-function/DeleteDialogModal";
import { getQuestionLevelList, getTechnologyList, getQuestionSetList } from "../redux/features/masterSlice";
import { getCandidateList } from "../redux/features/resourceSlice";
import { createCandidateExamSchedule, deleteCandidateExamSchedule, editCandidateExamScheduleList, getCandidateExamScheduleList, updateCandidateExamScheduleList } from "../redux/features/candidateExamSlice";
import { candidateListOption, questionLevelListOption, questionTypeListOption, setListOption, technologyListOption } from "../redux/features/optionsSlice";
import moment from "moment";


const initialFormData = Object.freeze({
    candidate_id: "",
    technology_id: "",
    level_id: "",
    set_id: "",
    scheduled_dated: "",
    scheduled_time: ""
});

const formErrData = Object.freeze({
    candidateErr: "Candidate name is required",
    technologyErr: "Technology is required",
    levelErr: "Question level is required",
    setErr: "Set is required",
    dateErr: "Schedule date  is required",
    timeErr: "Schedule time  is required"
});


const CandidateExamSchdule = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { questionlevel_List, technology_List, questionset_List } = useSelector(state => state.master)
    const { questionlevels, technologys, candidateName, sets } = useSelector(state => state.dropDownOption)

    const { status, error, candidateExamScheduleList, candidateExamScheduleUpdate, candidateExamScheduleDelete, candidateExamScheduleCreate, candidateExamScheduleEdit } = useSelector(state => state.candidate)
    const { candidateList } = useSelector(state => state.resource)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":10}`)
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [selectedTechnology, setSelectedTechnology] = useState("")
    const [selectedLevel, setSelectedLevel] = useState("")
    const [selectedSet, setSelectedSet] = useState("")
    const [selectedCandidate, setSelectedCandidate] = useState("")
    const [selectedScheduledDate, setSelectedScheduledDate] = useState(null)
    const [selectedScheduledTime, setSelectedScheduledTime] = useState(null)
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        dispatch(getCandidateExamScheduleList(filterQuery))
        dispatch(getQuestionLevelList(`{"order_by":{"column":"-id"},"no_limit":'True'}`))
        dispatch(getTechnologyList(`{"order_by":{"column":"-id"},"no_limit":'True'}`))
        dispatch(getCandidateList(`{"order_by":{"column":"-id"},"no_limit":'True'}`))
        dispatch(getQuestionSetList(`{"order_by":{"column":"-id"},"no_limit":'True'}`))
        dispatch(candidateListOption())
        dispatch(technologyListOption())
        dispatch(questionTypeListOption())
        dispatch(questionLevelListOption())
        dispatch(setListOption())
    }, [])

    if (reload === true && status === `${STATUSES.SUCCESS}_candidateExamScheduleUpdate` && candidateExamScheduleUpdate && candidateExamScheduleUpdate.message) {
        if (candidateExamScheduleUpdate.status === 200) {
            toast.success(candidateExamScheduleUpdate.message)
            dispatch(getCandidateExamScheduleList(filterQuery))
        } else {
            toast.error(candidateExamScheduleUpdate.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_candidateExamScheduleCreate` && candidateExamScheduleCreate && candidateExamScheduleCreate.message) {
        if (candidateExamScheduleCreate.status === 200) {
            toast.success(candidateExamScheduleCreate.message)
            dispatch(getCandidateExamScheduleList(filterQuery))
        } else {
            toast.error(candidateExamScheduleCreate.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_candidateExamScheduleDelete` && candidateExamScheduleDelete && candidateExamScheduleDelete.message) {
        if (candidateExamScheduleDelete.status === 200) {
            toast.success(candidateExamScheduleDelete.message)
            dispatch(getCandidateExamScheduleList(filterQuery))
        } else {
            toast.error(candidateExamScheduleDelete.message)
        }
        setReload(false)
    }

    const cleanState = () => {
        setSelectedCandidate("")
        setSelectedLevel("")
        setSelectedSet("")
        setSelectedTechnology("")
        setSelectedScheduledDate(null)
        setSelectedScheduledTime(null)
    }
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0); cleanState(initialFormData) } setOpen(false); cleanState(initialFormData);;
        setErrStatus(false)
    }

    if (loading === true && status === `${STATUSES.SUCCESS}_candidateExamScheduleList`) {
        if (candidateExamScheduleList.status === 200) {
        } else {
            toast.error(candidateExamScheduleList.message)
        }
        setLoading(false)
    }


    const submitData = (e) => {
        e.preventDefault()

        if (selectedCandidate === "" || selectedTechnology === "" || selectedLevel === "" || selectedSet === ""
            || selectedScheduledDate === null || selectedScheduledTime === null) {
            setErrStatus(true)
        }

        if (selectedCandidate && selectedTechnology && selectedLevel && selectedSet && selectedScheduledDate && selectedScheduledTime) {
            const ces_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`))
            let userId = ces_user.user && ces_user.user.data && ces_user.user.data.id
            let addvalues = {
                candidate: selectedCandidate.value,
                technology: selectedTechnology.value,
                question_level: selectedLevel.value,
                set: selectedSet.value,
                scheduled_date: selectedScheduledDate,
                scheduled_time: selectedScheduledTime,
                created_by: userId,
                status: true,
            }
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                dispatch(updateCandidateExamScheduleList({ ID, addvalues }))
            } else {
                dispatch(createCandidateExamSchedule(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}_candidateExamScheduleEdit` && candidateExamScheduleEdit && candidateExamScheduleEdit.data) {
        let editData = candidateExamScheduleEdit.data
        editData && editData.candidate && setSelectedCandidate({ label: editData.candidate.first_name, value: editData.candidate_id })
        editData && editData.question_level && setSelectedLevel({ label: editData.question_level.level_name, value: editData.question_level_id })
        editData && editData.set && setSelectedSet({ label: editData.set.set_name, value: editData.set_id })
        editData && editData.technology && setSelectedTechnology({ label: editData.technology.name, value: editData.technology_id })
        editData && editData.scheduled_date && setSelectedScheduledDate(editData.scheduled_date)
        editData && editData.scheduled_time && setSelectedScheduledTime(editData.scheduled_time)
        editData && setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(editCandidateExamScheduleList(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
    }

    const handleClickArchive = (id) => {
        setReload(true)
        setArchiveOpen(false)
        dispatch(deleteCandidateExamSchedule(id))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCandidateExamScheduleList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCandidateExamScheduleList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(questionlevel_List && questionlevel_List.data && questionlevel_List.data.rows && questionlevel_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getCandidateExamScheduleList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
        }
    }


    const actionColumn = [
        {
            field: "candidate_id", headerName: "Candidate Name", width: 230, type: 'singleSelect', valueOptions: candidateName, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.candidate.first_name} {params.row.candidate.last_name}
                    </div>
                );
            }
        },
        {
            field: "set_id", headerName: "Set", width: 230, type: 'singleSelect', valueOptions: sets, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.set ? params.row.set.set_name : "-"}
                    </div>
                );
            },
        },
        {
            field: "technology_id", headerName: "Technology", width: 230, type: 'singleSelect', valueOptions: technologys, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.technology ? params.row.technology.name : "-"}
                    </div>
                );
            },
        },
        {
            field: "question_level_id", headerName: "Question Level", width: 230, type: 'singleSelect', valueOptions: questionlevels, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.question_level ? params.row.question_level.level_name : "-"}
                    </div>
                );
            },
        },
        {
            field: "scheduled_date", headerName: "Scheduled Date", width: 200, filterable: false, renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {moment(params.row.scheduled_date).format('DD-MM-YYYY')}
                    </div>
                );
            },
        },
        { field: "scheduled_time", headerName: "Scheduled Time", width: 200, filterable: false, },

        {
            field: "status",
            headerName: "Status",
            width: 160,
            type: 'singleSelect',
            valueOptions: [{ value: 'True', label: 'Active' }, { value: 'False', label: 'Inactive' }],
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="candidate-exam-schedule"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={handleArchive} />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "is" ? 'eq' :
                    filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getCandidateExamScheduleList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getCandidateExamScheduleList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
        }
    }
    return (
        <div className="master">
            <Sidebar />
            <div className="masterContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Candidate Exam Schedule List</h3>
                        <div>
                            <Tooltip title="Add Candidate Exam Schedule">
                                <button onClick={handleOpen} className="btn-add-new"><Add /> </button>
                            </Tooltip>
                            <Tooltip title="Back">
                                <Link to={-1} className="btn-add-new"><ArrowBack /> </Link>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={candidateExamScheduleList && candidateExamScheduleList.data && candidateExamScheduleList.data.rows ? candidateExamScheduleList.data.rows : []}
                        columns={actionColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} Candidate Exam Schedule</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-master-form">
                                <InputSelectValueField
                                    label={'Candidate'}
                                    placeholder="Select Candidate"
                                    defaultValue={selectedCandidate}
                                    name={"candidate_id"}
                                    onChange={setSelectedCandidate}
                                    optionList={candidateList && candidateList.data && candidateList.data.rows.length > 0 ? candidateList.data.rows : []}
                                    optionLevel={['first_name', 'email']}
                                    optionValue={"id"}
                                    errStatus={errStatus}
                                    formErr={formErr.candidateErr}
                                />
                                <InputSelectValueField
                                    label={'Technology'}
                                    placeholder="Select Technology"
                                    defaultValue={selectedTechnology}
                                    name={"technology_id"}
                                    onChange={setSelectedTechnology}
                                    optionList={technology_List && technology_List.data && technology_List.data.rows.length > 0 ? technology_List.data.rows : []}
                                    optionLevel={['name']}
                                    optionValue={"id"}
                                    errStatus={errStatus}
                                    formErr={formErr.technologyErr}
                                />

                                <InputSelectValueField
                                    label={'Question Level'}
                                    placeholder="Select Question Level"
                                    defaultValue={selectedLevel}
                                    name={"level_id"}
                                    onChange={setSelectedLevel}
                                    optionList={questionlevel_List && questionlevel_List.data && questionlevel_List.data.rows.length > 0 ? questionlevel_List.data.rows : []}
                                    optionLevel={['level_name']}
                                    optionValue={"id"}
                                    errStatus={errStatus}
                                    formErr={formErr.levelErr}
                                />
                                <InputSelectValueField
                                    label={'Set'}
                                    placeholder="Select Set"
                                    defaultValue={selectedSet}
                                    name={"set_id"}
                                    onChange={setSelectedSet}
                                    optionList={questionset_List && questionset_List.data && questionset_List.data.rows.length > 0 ? questionset_List.data.rows : []}
                                    optionLevel={['set_name']}
                                    optionValue={"id"}
                                    errStatus={errStatus}
                                    formErr={formErr.setErr}
                                />
                                <SingleDatePickerInputField
                                    label={'Scheduled Dated'}
                                    placeholder="Select"
                                    defaultValue={selectedScheduledDate}
                                    name={"scheduled_dated"}
                                    onChange={setSelectedScheduledDate}
                                    format={'yyyy-MM-dd'}
                                    errStatus={errStatus}
                                    formErr={formErr.dateErr}
                                />
                                <SingleTimePickerInputField
                                    label={'Scheduled Time'}
                                    placeholder="Select"
                                    defaultValue={selectedScheduledTime}
                                    name={"scheduled_time"}
                                    onChange={setSelectedScheduledTime}
                                    format={'yyyy-MM-dd'}
                                    errStatus={errStatus}
                                    formErr={formErr.timeErr}
                                />
                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={'Archive'}
                    paragraph={'Are you sure to archive candidate exam'}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default CandidateExamSchdule