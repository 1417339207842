import "./profile.scss";
import React, { useState, useEffect } from 'react'
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
// import { useNavigate } from "react-router-dom";
import api from "../../redux/APIS/api";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Tooltip } from "@mui/material";

const Profile = () => {
  const navigate = useNavigate()
  const { Id } = useParams()
  const [user, setUser] = useState({})
  // const navigate=useNavigate()

  const loadProfile = async () => {
    if (Id > 0) {
      const res = await api.get(`/resources/main/${Id}`)
      .then().catch(error => console.log(error))
    if (res && res.data && res.data.data) {
      setUser(res.data.data);
    }
    } else {
      const res = await api.get('/user')
        .then().catch(error => console.log(error))
      if (res && res.data && res.data.data) {
        setUser(res.data.data);
      }
    }
  }

  // const onEdit=() => { 
  //   // console.log(user);
  //   navigate("/edit-profile", {state:user})
  //  }

  useEffect(() => {
    loadProfile()
  }, [])

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">

            <div className="item">
              {user.image ?
                <img src={user.image}
                  alt="" className="itemImg" />
                :
                <img src="https://images.unsplash.com/photo-1504376379689-8d54347b26c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=536&q=80"
                  alt="" className="itemImg" />
              }
            </div>
          </div>
          <div className="right">
            {/* <Link to={"/edit-profile"} className="editButton">Edit</Link> */}
            {/* <button onClick={onEdit} className="editButton">Edit</button> */}
            <Tooltip title="Back">
            <button  className="editButton" onClick={() => navigate(-1)}><ArrowBackIcon /></button>
            </Tooltip>
            <h1 className="title">Information</h1>
            <div className="details">
              {user.first_name &&
                <div className="detailItem">
                  <span className="itemKey">Name :</span>
                  <span className="itemKey2">{user.first_name} {user.last_name && user.last_name}</span>
                </div>
              }
              {user.email &&
                <div className="detailItem">
                  <span className="itemKey">Email :</span>
                  <span className="itemKey2">{user.email}</span>
                </div>
              }
              {user.contact_no &&
                <div className="detailItem">
                  <span className="itemKey">Contact no. :</span>
                  <span className="itemKey2">{user.contact_no}</span>
                </div>
              }
              {user.date_of_birth &&
                <div className="detailItem">
                  <span className="itemKey">Date of Birth :</span>
                  <span className="itemKey2"> {user.date_of_birth}</span>
                </div>
              }
              {user.tms_role && user.tms_role.role_name &&
                <div className="detailItem">
                  <span className="itemKey">Role :</span>
                  <span className="itemKey2">{user.tms_role.role_name}</span>
                </div>
              }
              {user.department && user.department.name &&
                <div className="detailItem">
                  <span className="itemKey">Department :</span>
                  <span className="itemKey2">{user.department.name}</span>
                </div>
              }
              {user.address &&
                <div className="detailItem">
                  <span className="itemKey">Address :</span>
                  <span className="itemKey2"> {user.address}</span>
                </div>
              }

              {user.city && user.city.city_name &&
                <div className="detailItem">
                  <span className="itemKey">City :</span>
                  <span className="itemKey2">{user.city.city_name}</span>
                </div>
              }
              {user.state && user.state.name &&
                <div className="detailItem">
                  <span className="itemKey">State :</span>
                  <span className="itemKey2">{user.state.name}</span>
                </div>
              }
              {user.country && user.country.name &&
                <div className="detailItem">
                  <span className="itemKey">Country :</span>
                  <span className="itemKey2">{user.country.name}</span>
                </div>
              }

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Profile