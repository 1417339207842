import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Checkbox, Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { QuestionColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, AddCircleOutline, Close, Download, RemoveCircleOutline } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields, InputSelectField, InputTextareaField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import { getAnswerOptions, getQuestionList, importQusetionFile, postAnswerOptions, questionArchive, questionCreate, questionEdit, questionUpdate, updateAnswerOptions } from "../../redux/features/masterSlice";
import { questionLevelListOption, questionTypeListOption, technologyListOption } from "../../redux/features/optionsSlice";
import Filter from "../../components/Filter";
import LocalStorageDataService from '../../components/common-function/GetLocalstorage';
import dataService from "../../redux/services/data.service";

const initialFormData = Object.freeze({
    title: "",
    technology: "",
    question_level: "",
    question_type: "",
    marks: "",
    images: "",
    description: "",
    status: "",
});

const formErrData = Object.freeze({
    technologyErr: "Technology is required",
    questionlevelErr: "Question level is required",
    questiontypeErr: "Question type is required",
    marksErr: "Mark is required",
    titleErr: "Title is required",
    descriptionErr: "Description is required",
    statusErr: "Status is required",
    answerOptionErr: "Pleas add answer option",
});

const Question = () => {
    const { REACT_APP_API_BASEURL } = process.env;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, question_List, question_Create, question_Edit, question_Update, question_Archive,
        import_question_file, answer_options_List } = useSelector(state => state.master)

    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":10}`)
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const { technologys, questionlevels, questiontypes } = useSelector(state => state.dropDownOption)

    // custom filter
    const [fieldName, setFieldName] = useState("")
    const [defaultValue, setDefaultValue] = useState({})

    // file uploading states
    const uploadInputRef = React.useRef(null);

    const [answerFieldActive, setAnswerFieldActive] = useState(false)
    const [answerOptionsArray, setAnswerOptionsArray] = useState([])
    const [makeAnswerOptionData, setMakeAnswerOptionData] = useState("")

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
    }, [error])

    useEffect(() => {
        dispatch(getQuestionList(filterQuery))
        dispatch(questionLevelListOption())
        dispatch(technologyListOption())
        dispatch(questionTypeListOption())
    }, [])

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getQuestionList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getQuestionList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(question_List && question_List.data && question_List.data.rows && question_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getQuestionList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
        }
    }

    const actionColumn = [
        {
            field: "technology_id", headerName: "Technology", width: 170, renderCell: (params) => {
                return (<div>{params.row.technology}</div>);
            },
        },
        {
            field: "question_type_id", headerName: "Question Type", width: 150, renderCell: (params) => {
                return (<div>{params.row.question_type}</div>);
            },
        },
        {
            field: "question_level_id", headerName: "Question Level", width: 190, renderCell: (params) => {
                return (<div>{params.row.question_level}</div>);
            },
        },
        {
            field: "status", headerName: "Status", width: 100, renderCell: (params) => {
                return (<div className={`cellWithStatus ${params.row.status}`}>
                    {params.row.status === true ? 'Active' : 'Inactive'} </div>);
            },
        },
        {
            field: "action", headerName: "Action", width: "90", hideable: false, filterable: false, sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="question-level"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={handleArchive} />
                    </div>
                );
            },
        },
    ];

    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            fieldName === "technology_id" ?
                <Filter options={technologys} onChange={handleFilterChange} defaultValue={defaultValue} />
                : fieldName === "question_level_id" ?
                    <Filter options={questionlevels} onChange={handleFilterChange} defaultValue={defaultValue} />
                    : fieldName === "question_type_id" ?
                        <Filter options={questiontypes} onChange={handleFilterChange} defaultValue={defaultValue} />
                        : ""
        );
    }

    const columns = React.useMemo(() =>
        actionColumn.map((col) => {
            if (col.field === "technology_id" || col.field === "question_level_id" || col.field === 'question_type_id') {
                return {
                    ...col,
                    filterOperators: getGridSingleSelectOperators()
                        .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                        .map((operator) => ({
                            ...operator,
                            InputComponent: operator.InputComponent
                                ? CustomFilterInputValue
                                : undefined
                        }))
                };
            }
            return col;
        }),
        [actionColumn]
    );

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getQuestionList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getQuestionList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
            setDefaultValue("")
        }
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0) }
        setOpen(false);
        setFormValues(initialFormData);
        setErrStatus(false)
        setAnswerFieldActive(false)
        setAnswerOptionsArray([])
        setMakeAnswerOptionData("")
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_question_Update` && question_Update && question_Update.message) {
        if (question_Update.status === 200) {
            if (makeAnswerOptionData) {
                makeAnswerOptionData.map((item) => {
                    item['question'] = question_Update.data.id
                    item['status'] = true
                    item['created_by'] = LocalStorageDataService.userDetail().id
                    const addvalues = item
                    if (item && item.id) {
                        const ID = item.id;
                        dispatch(updateAnswerOptions({ ID, addvalues }))
                    } else {
                        dispatch(postAnswerOptions(item))
                    }
                })
            }
            toast.success(question_Update.message)
            dispatch(getQuestionList(filterQuery))
            handleClose()
        } else {
            toast.error(question_Update.message)
        }
        setReload(false)
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_question_Create` && question_Create && question_Create.message) {
        if (question_Create.status === 200) {
            if (makeAnswerOptionData) {
                makeAnswerOptionData.map((item) => {
                    item['question'] = question_Create.data.id
                    item['status'] = true
                    item['created_by'] = LocalStorageDataService.userDetail().id
                    dispatch(postAnswerOptions(item))
                })
            }
            toast.success(question_Create.message)
            dispatch(getQuestionList(filterQuery))
            handleClose()
        } else {
            toast.error(question_Create.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_question_Archive` && question_Archive && question_Archive.message) {
        if (question_Archive.status === 200) {
            toast.success(question_Archive.message)
            dispatch(getQuestionList(filterQuery))
        } else {
            toast.error(question_Archive.message)
        }
        setReload(false)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
    }

    const submitData = (e) => {
        e.preventDefault()
        if (formValues.set_name === "" || formValues.technology === "" || formValues.level === "" || formValues.no_of_question === ""
            || formValues.maximum_time === "" || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.set_name !== "" && formValues.technology !== "" && formValues.level !== "" && formValues.no_of_question !== ""
            && formValues.maximum_time !== "" && formValues.status !== "") {
            setErrStatus(false)
            setReload("submit")

            let addvalues = ({
                ...formValues,
                title: formValues.title,
                marks: formValues.marks,
                description: formValues.description,
                status: formValues.status,
                images: formValues.images,
                technology: formValues.technology.value ? formValues.technology.value : formValues.technology,
                question_level: formValues.question_level.value ? formValues.question_level.value : formValues.question_level,
                question_type: formValues.question_type.value ? formValues.question_type.value : formValues.question_type,
                created_by: LocalStorageDataService.userDetail().id
            })

            if (ID) {
                dispatch(questionUpdate({ ID, addvalues }))
            } else {
                dispatch(questionCreate(addvalues))
            }

            if (answerOptionsArray) {
                setMakeAnswerOptionData(answerOptionsArray)
            }
        }
    }

    const selectValueTechnologyLable = (id) => {
        let data = technologys && technologys;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.name, name: "technology" })
        })
        return newObject
    }

    const selectValueQuestionLevelLable = (id) => {
        let data = questionlevels && questionlevels;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.level_name, name: "question_level" })
        })
        return newObject
    }

    const selectValueQuestionTypeLable = (id) => {
        let data = questiontypes && questiontypes;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.question_type, name: "question_type" })
        })
        return newObject
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && question_Edit && question_Edit.data) {
        let editData = question_Edit.data
        setFormValues({
            technology: selectValueTechnologyLable(editData.technology_id),
            question_level: selectValueQuestionLevelLable(editData.question_level_id),
            question_type: selectValueQuestionTypeLable(editData.question_type_id),
            title: editData.title,
            description: editData.description,
            marks: editData.marks,
            status: editData.status,
            images: editData.images,
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)

        const newData = questiontypes.find((e => e.id === editData.question_type_id))
        const question_type_value = newData.question_type_key
        let result = question_type_value.match("MCQs") || question_type_value.match("mcqs") || question_type_value.match("MCQ") || question_type_value.match("mcq") || question_type_value.match("Mcq");
        if (result) {
            dispatch(getAnswerOptions(`{"filter":{"question_id":{"value":"${editData.id}","operation":"eq"}},"no_limit":True }`))
            setAnswerFieldActive(true)
            setReload('answer_option')
        } else {
            setAnswerFieldActive(false)
        }
    }

    if (reload === "answer_option" && status === `${STATUSES.SUCCESS}_answer_options_List` && answer_options_List && answer_options_List.message) {
        if (answer_options_List.data) {
            let array = []
            answer_options_List.data.rows && answer_options_List.data.rows.map(e => {
                return array.push({
                    id: e.id, is_correct: e.is_correct, answer_option: e.answer_option,
                    created_by: e.created_by, question: e.question_id
                })
            })
            array.length > 0 && setAnswerOptionsArray(array)
            setReload(false)
        }
    }

    const handleClickEdit = (id) => {
        dispatch(questionEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
    }

    const handleClickArchive = (id) => {
        setReload(true)
        setArchiveOpen(false)
        dispatch(questionArchive(id))
    }

    const handleQuestionTypeChange = (evt) => {
        if (evt) {
            setFormValues({ ...formValues, 'question_type': evt.value })
            if (evt.label) {
                let result = evt.label.match("MCQs") || evt.label.match("mcqs") || evt.label.match("MCQ") || evt.label.match("mcq") || evt.label.match("Mcq");
                if (result) {
                    setAnswerFieldActive(true)
                    setAnswerOptionsArray([...answerOptionsArray, {
                        answer_option: "", is_correct: false
                    }])
                } else {
                    setAnswerFieldActive(false)
                }
            }
        }
    }

    // import file code here start
    if (reload === "import_file" && import_question_file && import_question_file.status) {
        if (import_question_file.status === 200) {
            toast.success(import_question_file.message)
            dispatch(getQuestionList(filterQuery))
        } else {
            toast.error(import_question_file.message)
        }
        setReload(false)
    }

    const handleImport = (event) => {
        let file = event.target.files[0]
        let type = file.type
        const formData = new FormData();
        formData.append("file", file);
        const fileType = file.name.split(".")[1];

        if (fileType === "xlsx") {
            setReload('import_file')
            dispatch(importQusetionFile(formData))
        } else {
            toast.error("You can only import excel file")
        }
    }
    // import file code end

    // download sapmle file code here
    const downloadSampleFile = () => {
        let url = REACT_APP_API_BASEURL + "evaluation/candidate/download/sample"
        window.open(url);
    }

    // upload image code 
    const onFileChange = (event) => {
        let file = event.target.files[0]
        setFormValues({ ...formValues, images: file.name });
    }

    //answer option logic
    const addAnswerOptions = () => {
        setAnswerOptionsArray([...answerOptionsArray, {
            answer_option: "", is_correct: false
        }])
    }

    const removeAnswerOptions = async (i, id = null) => {
        if (id) {
            const request = { status: 3 }
            const response = await dataService.answer_option_delete(id, request)
            if (response && response.data && response.data.status === 200) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        }
        const updateformData = answerOptionsArray.filter((item, index) => index !== i);
        setAnswerOptionsArray(updateformData)
    }

    const handleAnswerOption = (evt, index = null) => {
        let { name, type, value, checked } = evt.target || evt;
        value = type === "checkbox" ? checked : value;
        const valueArray = answerOptionsArray.length > 0 && answerOptionsArray.map((item, i) => i === index ?
            { ...item, [name]: value } : item);

        if (name === 'is_correct') {
            answerOptionsArray.length > 0 && answerOptionsArray.map((e, i) => { e[index] === i ? e['is_correct'] = true : e['is_correct'] = false })
        }
        index !== null && setAnswerOptionsArray([...valueArray])
    }

    return (
        <div className="master">
            <Sidebar />
            <div className="masterContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Question List</h3>
                        <div>
                            <Tooltip title="Add New Question">
                                <button onClick={handleOpen} className="btn-add-new"><Add /> </button>
                            </Tooltip>
                            <input
                                id='fileUpload'
                                ref={uploadInputRef}
                                type='file'
                                multiple
                                style={{ display: "none" }}
                                onChange={handleImport}
                                name="file"
                            />
                            <Tooltip title="Import File">
                                <button
                                    onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                                    className="btn-add-new">Import </button>
                            </Tooltip>
                            <Tooltip title="Download Sample file">
                                <button className="btn-add-new" onClick={downloadSampleFile}><Download /> Sample File </button>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={question_List && question_List.data && question_List.data.rows ? question_List.data.rows : []}
                        columns={QuestionColumns.concat(columns)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"lg"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add New'} Question</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-master-form">
                                <InputSelectField
                                    label={'Technology'}
                                    placeholder="Select Technology"
                                    defaultValue={formValues.technology}
                                    name={"technology"}
                                    onChange={handleChange}
                                    optionList={technologys ? technologys : []}
                                    optionLevel={['name']}
                                    optionValue={"id"}
                                    errStatus={errStatus}
                                    formErr={formErr.technologyErr}
                                />
                                <InputSelectField
                                    label={'Question Level'}
                                    placeholder="Select Question Level"
                                    defaultValue={formValues.question_level}
                                    name={"question_level"}
                                    onChange={handleChange}
                                    optionList={questionlevels ? questionlevels : []}
                                    optionLevel={['level_name']}
                                    optionValue={"id"}
                                    errStatus={errStatus}
                                    formErr={formErr.questionlevelErr}
                                />
                            </div>
                            <div className="add-new-master-form">
                                <InputTextareaField
                                    label={"Title"}
                                    name="title"
                                    defaultValue={formValues.title}
                                    type="text"
                                    placeholder="Title"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.titleErr}
                                />
                            </div>
                            <div className="add-new-master-form">
                                <div className="formInput" style={{ flexBasis: "100%" }}>
                                    <InputSelectField
                                        label={'Question Type'}
                                        placeholder="Select Question Type"
                                        defaultValue={formValues.question_type}
                                        name={"question_type"}
                                        onChange={handleQuestionTypeChange}
                                        optionList={questiontypes ? questiontypes : []}
                                        optionLevel={['question_type']}
                                        optionValue={"id"}
                                        errStatus={errStatus}
                                        formErr={formErr.questiontypeErr}
                                    />
                                </div>
                            </div>

                            <div className="add-new-master-form">
                                <div>
                                    {answerFieldActive === true &&
                                        <div className="formInput">
                                            Answer Options
                                            <Tooltip title="Add More Answer Options">
                                                <AddCircleOutline onClick={() => addAnswerOptions()} style={{ marginLeft: "5px", color: "green", cursor: "pointer" }} />
                                            </Tooltip>
                                        </div>
                                    }
                                    {answerFieldActive === true && answerOptionsArray.map((item, i) => {
                                        return (
                                            <div className="add-new-user-form" key={i} style={{ display: "flex" }}>

                                                <div className="formInput">
                                                    <label >
                                                        Answer Option
                                                    </label>
                                                    <input
                                                        name={"answer_option"}
                                                        onChange={(e) => handleAnswerOption(e, i)}
                                                        type="text"
                                                        placeholder=" Answer Option"
                                                        value={item.answer_option}
                                                    />
                                                </div>

                                                <div className="formInput" style={{ flexBasis: "43%", marginLeft: "30px" }}>
                                                    <label >
                                                        Is Correct
                                                    </label>
                                                    <Checkbox
                                                        className="checkbox"
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        name={"is_correct"}
                                                        checked={item.is_correct}
                                                        onChange={(e) => handleAnswerOption(e, i)}
                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                                    />

                                                </div>

                                                <div className="formInput" style={{ marginTop: '6%' }}>
                                                    {
                                                        <Tooltip title="Remove">
                                                            <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }}
                                                                onClick={() => removeAnswerOptions(i, item.id)}
                                                            />
                                                        </Tooltip>
                                                    }
                                                </div>

                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>

                            <div className="add-new-master-form">
                                <InputTextareaField
                                    label={"Description"}
                                    name="description"
                                    defaultValue={formValues.description}
                                    type="text"
                                    placeholder="Description"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />
                                <InputFields
                                    label={"Mark"}
                                    name="marks"
                                    defaultValue={formValues.marks}
                                    type="number"
                                    placeholder="Mark"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.marksErr}
                                    min="1"
                                />
                                <div className="formInput">
                                    <label >
                                        Upload Image {ID && formValues.images ? "(" + formValues.images + ")" : ""}
                                    </label>
                                    <input
                                        name={"images"}
                                        onChange={onFileChange}
                                        type="file"
                                        placeholder="Upload Image"
                                        style={{ padding: "7px" }}
                                        accept='image/jpg, image/png,image/jpeg'
                                    />
                                </div>

                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />
                            </div>
                            <button
                                className={reload === "submit" ? "submit-btn-disabled" : "submit-modal"}
                                onClick={submitData}
                                disabled={reload === "submit" ? true : false}
                            >
                                {ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                            </button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={'Archive'}
                    paragraph={'Are you sure to archive question'}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default Question