import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    getExamQuestions,
    getStatusScheduleExam,
    completeCandidateExamPost
} from "../../redux/features/candidateExamSlice";
import moment from "moment";
import screenfull from "screenfull";
import { STATUSES } from "../../redux/common-status/constant";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { CopyBlock, Snippet } from "react-code-blocks";
import Editor from "@monaco-editor/react";
import dataService from "../../redux/services/data.service";
import { CircularProgress } from "@mui/material";



const useRestrictCopyPaste = (props) => {
    useEffect(() => {
        props.actions?.forEach((action) => {
            action && window.addEventListener(action, preventPaste);
        });
        return () => {
            props.actions.forEach((action) => {
                action && window.removeEventListener(action, preventPaste);
            });
        };
    }, [props.window, props.actions]);

    const preventPaste = (e) => {
        //alert("Copying and pasting is not allowed!");
        e.preventDefault();
    };
};


export default function TestCandidateForm() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const candidateSelectedExam = useLocation();

    const [iseExamStarted, setIsExamStarted] = useState(false);
    const [examDuration] = useState(
        candidateSelectedExam.state.data.set.maximum_time
    );
    const [totalQuestions] = useState(
        candidateSelectedExam.state.data.set.no_of_question
    );

    const [currentDateTime, setCurrentDateTime] = useState(
        moment().format("YYYY-MM-DD h:mm A")
    );
    const [examStartDateTime, setExamStartDateTime] = useState("");
    const { status, error, candidateSelectedExamQuestions, statusScheduleExam, examResultData } = useSelector(
        (state) => state.candidate
    );
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [myAnswer, setMyAnswer] = useState({});
    const [score, setScore] = useState(0);
    const [finish, setFinish] = useState(false);
    const [, setShow] = useState(false);
    const [, setClickAnswer] = useState(false);
    const [loader, setLoader] = useState(true);
    const [questList, setQuestList] = useState([]);
    const [programmingEvaluetionIds, setProgrammingEvaluetionIds] = useState({})
    const [timer, setTimer] = useState('00:00:00');
    const [userOutput, setUserOutput] = useState("");
    const [userCode, setUserCode] = useState('');
    const [loading, setLoading] = useState(false);
    let UpdatedUserId = ""

    const Ref = useRef(null);
    const exam_submit = useRef(null);


    const disableEnspectRightClick = () => {
        // Disable right-click
        document.addEventListener("contextmenu", (e) => e.preventDefault());

        function ctrlShiftKey(e, keyCode) {
            return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
        }

        document.onkeydown = (e) => {
            // Disable F12,F122, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
            if (
                e.keyCode === 17 ||
                e.keyCode === 27 ||
                e.keyCode === 116 ||
                e.keyCode === 122 ||
                e.keyCode === 123 ||
                ctrlShiftKey(e, "I") ||
                ctrlShiftKey(e, "J") ||
                ctrlShiftKey(e, "C") ||
                ctrlShiftKey(e, "R") ||
                (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
            )
                return false;
        };
    };

    useEffect(() => {
        disableEnspectRightClick();
        const element = document.getElementById("target");
        if (element) {
            element.style.background = "white";
            if (screenfull) {
                screenfull.request(element);
            }

        }
        candidateSelectedExam.state && candidateSelectedExam.state.data && dispatch(getExamQuestions(candidateSelectedExam.state.data.id));
    }, []);

    //current date and time
    setInterval(() => {
        setCurrentDateTime(() =>
            moment().format("YYYY-MM-DD h:mm A")
        );
    }, 60000);

    const startExam = () => {
        setIsExamStarted(true);
        setExamStartDateTime(moment().format("YYYY-MM-DD h:mm A"));
        dispatch(getStatusScheduleExam({ exam_schedule_id: candidateSelectedExam.state.data.id, schedule_status: 2 }))
        onClickReset()
    };

    const examDurationOver = () => {
        navigate("/dashboard");
    };

    const submitExam = () => {
        const element = document.getElementById("target");
        element.style.background = "white";
        let array = [];
        let array2 = [];
        let total_marks = 0; questList.map((x) => total_marks += x.marks);

        Object.keys(myAnswer).forEach(function (key, index) {
            let filterData = questList.filter((x) => x.id == key);
            if (filterData.length > 0 && filterData[0].question_type_key === "mcq") {
                let data = {
                    [key]: myAnswer[key]
                    // marks: filterData[0].marks,
                    // question_type: filterData[0].question_type,
                    // question: key,
                    // selectedOption: myAnswer[key],
                };
                array.push(data);
            }
            if (filterData.length > 0 && filterData[0].question_type_key === "programming") {
                let data = {
                    [key]: myAnswer[key]
                    //marks: filterData[0].marks,
                    // question_type: filterData[0].question_type,
                    // programing_question_evalution: key,
                    // code: myAnswer[key],
                };
                array2.push(data);
            }
        });
        let answer = {
            "candidate": candidateSelectedExamQuestions.data.candidate,
            "candidate_exam_schedule": candidateSelectedExamQuestions.data.id,
            "exam_start_datetime": new Date(examStartDateTime),
            "exam_end_datetime": new Date(),
            "total_marks": total_marks,
            "total_answered_questions": Object.keys(myAnswer).length,
            "total_unanswered_question": totalQuestions - Object.keys(myAnswer).length,
            //"total_time_taken": 40,
            'all_question_data': questList,
            "candidate_question_answer_data": {
                "mcq": array,
                "programing": array2
            },
            "status": true,
            "created_by": GetLocalstorage.userDetail().id
        }

        let txt = "You pressed OK!";
        let status = false
        if (timer == '00:00:00') {
            status = true
        } else {
            if (window.confirm("Press a button!")) {
                txt = "You pressed OK!";
                status = true
            } else {
                txt = "You pressed Cancel!";
                screenfull.request(element);
                status = false
            }
        }

        if (status === true) {
            setLoader('submit')
            //clearInterval(Ref.current)
            dispatch(completeCandidateExamPost(answer))
        }
    };

    if (loader === 'submit' && status === `${STATUSES.SUCCESS}_examResultData` && examResultData && examResultData.data) {
        setLoader('final_destination')
        dispatch(getStatusScheduleExam({ exam_schedule_id: candidateSelectedExam.state.data.id, schedule_status: 3 }))
    }

    if (loader === 'submit' && status === `${STATUSES.FAILURE}` && error) {
        setLoader(false)
        alert(error.message)
    }

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }

    const clearTimer = (e) => {

        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next    
        //setTimer('00:00:00');

        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            let { total, hours, minutes, seconds }
                = getTimeRemaining(e);
            if (total >= 0) {

                // update the timer
                // check if less than 10 then we need to 
                // add '0' at the beginning of the variable
                setTimer(
                    (hours > 9 ? hours : '0' + hours) + ':' +
                    (minutes > 9 ? minutes : '0' + minutes) + ':'
                    + (seconds > 9 ? seconds : '0' + seconds)
                )
            } else {
                clearInterval(Ref.current)
                exam_submit.current.click()
            }
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if 
        // you entend to add more time
        //examDuration
        deadline.setMinutes(deadline.getMinutes() + examDuration);
        return deadline;
    }
    const onClickReset = () => {
        clearTimer(getDeadTime());
    }


    if (
        loader === true &&
        candidateSelectedExamQuestions &&
        candidateSelectedExamQuestions.data
    ) {

        setLoader(false);
        setQuestList(candidateSelectedExamQuestions.data.question_data);
    }

    const checkAnswer = (quest, variant) => {
        setMyAnswer({ ...myAnswer, [quest.id]: variant.id });
        setClickAnswer(true);
    };


    const checkCorrectAnswer = () => {
        if (
            myAnswer === candidateSelectedExamQuestions.data &&
            candidateSelectedExamQuestions.data.question_data[currentQuestion].answer
        ) {
            setScore(score + 1);
        }
    };

    const updateDraft = () => {

        let questionId = questList[currentQuestion] && questList[currentQuestion].id
        let userOutputis = programmingEvaluetionIds[questionId].userOutput
        setProgrammingEvaluetionIds({ ...programmingEvaluetionIds, [questionId]: { userCode, userOutput: userOutputis, variant: myAnswer[questionId] } })
    }

    const apiCall = async (data, type) => {
        const res = await dataService.exam_compile_post(data)
        const result = await res.data
        setLoading(false);
        let userOutputis = ""
        let questionId = questList[currentQuestion] && questList[currentQuestion].id
        let variant = ""
        if (result.data) {
            variant = result.data.id
            userOutputis = result.data.output_data.output
        } else {
            userOutputis = result.message
        }
        setMyAnswer({ ...myAnswer, [questionId]: variant });
        setProgrammingEvaluetionIds({ ...programmingEvaluetionIds, [questionId]: { userCode, userOutput: type !== 'draft' && userOutputis, variant } })
        type !== 'draft' && setTimeout(setUserOutput(userOutputis), 500);

    }

    // Function to call the compile endpoint
    function compile(type) {
        if (userCode === ``) {
            return;
        }
        let data = {
            "candidate": candidateSelectedExam.state.data.candidate_id,
            "candidate_exam_schedule": candidateSelectedExam.state.data.id,
            "question": questList[currentQuestion] && questList[currentQuestion].id,
            "technology_key": candidateSelectedExam.state.data && candidateSelectedExam.state.data.technology.technology_key,
            "code": userCode,
            'id': myAnswer[currentQuestion] ? myAnswer[currentQuestion] : ""

        }

        setLoading(true);
        apiCall(data, type)
    }

    // Function to clear the output screen
    function clearOutput() {
        setUserOutput("");
    }

    const reset = (type) => {
        setShow(false);
        setClickAnswer(false);

        setLoading(false)
        let quesID = questList[currentQuestion].id
        if (questList[currentQuestion] && questList[currentQuestion].question_type_key === 'programming' && !programmingEvaluetionIds[quesID]) {
            if (userCode) {
                compile('draft')
            }
        }
        if (questList[currentQuestion] && questList[currentQuestion].question_type_key === 'programming' && programmingEvaluetionIds[quesID]) {
            //if(userCode){
            updateDraft('updateDraft')
            //}
        }
        let activeQuestion = type === 'PREVIOUS' ? currentQuestion - 1 : currentQuestion + 1;
        if (questList[activeQuestion] && questList[activeQuestion].question_type_key === 'programming') {
            let qusetion_id = questList[activeQuestion].id
            let user_code = programmingEvaluetionIds && programmingEvaluetionIds[qusetion_id] && programmingEvaluetionIds[qusetion_id].userCode ? programmingEvaluetionIds[qusetion_id].userCode : ``;
            let user_output = programmingEvaluetionIds && programmingEvaluetionIds[qusetion_id] && programmingEvaluetionIds[qusetion_id].userOutput ? programmingEvaluetionIds[qusetion_id].userOutput : ``;
            user_code ? setUserCode(user_code) : setTimeout(setUserCode(''), 500);
            user_output ? setUserOutput(user_output) : setTimeout(setUserOutput(''), 500);
        } else {
            setUserCode('')
            setUserOutput('')
        }
    };

    const finishHandler = () => {
        setFinish(true);

        if (
            currentQuestion === candidateSelectedExamQuestions.data &&
            candidateSelectedExamQuestions.data.question_data.length - 1
        ) {
            setFinish(true);
        }
        setTimeout(() => {
            examDurationOver()
        }, 5000);
    };

    if (loader === 'final_destination' && status === `${STATUSES.SUCCESS}_statusScheduleExam` && statusScheduleExam && statusScheduleExam.status) {
        setLoader(false)
        finishHandler()
    }


    let next = currentQuestion < questList.length - 1;
    let previous = true;
    // this line disable code
    useRestrictCopyPaste({ window, actions: ["copy", "cut", "paste"] });

    return (
        <>
            <div className="new">
                <div className="newContainer">
                    {finish ? (
                        <div
                            className="thanksMsg"
                            style={{ marginTop: "80px", textAlign: "center" }}
                        >
                            <h2>
                                Your test is finished. We will connect with you asap. Thanks!
                            </h2>
                            <p>Plaese wait... It will redirect on dashboard in 5 seconds</p>
                        </div>
                    ) : (
                        <div id="target">
                            <div className="row top-headings">
                                <h3>{candidateSelectedExam.state.data.technology.name} Test</h3>
                            </div>
                            <div className="termand-condition-data" style={{ position: "relative" }}>
                                <div className="row head-line">
                                    <div className="col">
                                        <strong>Current Date & Time : </strong> {currentDateTime}
                                    </div>
                                    <div className="col">
                                        <strong>
                                            {examStartDateTime != "" ? "Test Start Time :" : ""}{" "}
                                        </strong>
                                        {examStartDateTime}
                                    </div>
                                    <div className="col">
                                        <strong>Duration : </strong>
                                        {examDuration} Minutes
                                    </div>
                                </div>
                                <div className="row head-line">
                                    <div className="col">
                                        <strong>
                                            {examStartDateTime != "" ? "Answered :" : ""}
                                        </strong>{" "}
                                        {Object.keys(myAnswer).length > 0
                                            ? Object.keys(myAnswer).length + " of " + totalQuestions
                                            : ''}
                                    </div>

                                    <div className="col"></div>
                                    <div className="col">
                                        <strong>
                                            {examStartDateTime != "" ? "Time Remaining :" : ""}
                                        </strong>
                                        {examStartDateTime != "" ? (
                                            <h2>{timer}</h2>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                <div className="row head-line">
                                    <div className="col"></div>
                                    <div className="col"></div>
                                    <div className="col">
                                        {!iseExamStarted ? (
                                            <button
                                                className="btn btn-primary btn-md"
                                                onClick={() => startExam()}
                                            >
                                                Start Test
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-primary btn-md"
                                                onClick={() => submitExam()}
                                                ref={exam_submit}
                                            >
                                                Submit Test
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="row head-line">
                                    <hr />
                                    {
                                        iseExamStarted && (
                                            <div style={{ overflow: "scroll", height: "700px" }} className="container m-4 p-4 mx-auto h-min-screen grid grid-rows-1 grid-cols-1 items-center">
                                                <div className="wrapper">
                                                    <p className="question-wrapper">
                                                        <strong>Question {currentQuestion + 1} : </strong>{" "}
                                                        {candidateSelectedExamQuestions.data && <CopyBlock
                                                            language="java"
                                                            text={candidateSelectedExamQuestions.data.question_data[
                                                                currentQuestion
                                                            ].title}
                                                            codeBlock
                                                            theme={Snippet}
                                                            showLineNumbers={false}
                                                        />}

                                                    </p>
                                                    <p>
                                                        <strong>Answer : </strong>
                                                    </p>

                                                    {candidateSelectedExamQuestions.data &&
                                                        candidateSelectedExamQuestions.data.question_data[
                                                            currentQuestion
                                                        ].question_type_key === "programming" ? (
                                                        <div className="m-2 h-14 border-2 border-black mx-auto">
                                                            <div className="main">
                                                                <div className="left-container" style={{ float: "left", width: "50%", height: "calc(50vh - 100px)" }}>
                                                                    <Editor
                                                                        options={{ fontSize: 20 }}

                                                                        width="100%"
                                                                        theme={'vs-dark'}
                                                                        language={candidateSelectedExam.state.data.technology.technology_key}
                                                                        defaultLanguage="javascript"
                                                                        //value={}
                                                                        value={userCode}
                                                                        saveViewState={true}
                                                                        onChange={(value) => { setUserCode(value); }}
                                                                    />
                                                                    <button className="run-btn" onClick={() => compile()}>
                                                                        Run
                                                                    </button>
                                                                </div>
                                                                <div className="right-container" style={{ float: "left", width: "50%", height: "calc(50vh - 100px)" }}>

                                                                    <h4>Output:</h4>
                                                                    {loading ? (
                                                                        <div className="spinner-box">
                                                                            {/* <img src={spinner} alt="Loading..." /> */}
                                                                            <CircularProgress />
                                                                        </div>
                                                                    ) : (
                                                                        <div className="output-box">
                                                                            <pre>{UpdatedUserId ? UpdatedUserId : userOutput}</pre>
                                                                            <button onClick={() => { clearOutput() }}
                                                                                className="clear-btn">
                                                                                Clear
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        candidateSelectedExamQuestions.data &&
                                                        candidateSelectedExamQuestions.data.question_data[
                                                            currentQuestion
                                                        ].answer_option.map((variant, i) => (
                                                            <div key={i} className="m-2 h-14 border-2 border-black mx-auto">
                                                                <p style={{ paddingLeft: "70px" }}>
                                                                    <input
                                                                        onClick={() =>
                                                                            checkAnswer(
                                                                                questList[currentQuestion],
                                                                                variant
                                                                            )
                                                                        }
                                                                        type="radio"
                                                                        name="site_name"
                                                                        className="radio"
                                                                        checked={
                                                                            myAnswer[
                                                                            questList[currentQuestion] &&
                                                                            questList[currentQuestion].id
                                                                            ] === variant.id
                                                                        }
                                                                        value={"ss"}
                                                                    />
                                                                    {" " + variant.answer_option}
                                                                </p>
                                                            </div>
                                                        ))
                                                    )}

                                                    <div className="btns" >
                                                        {previous && currentQuestion !== 0 && (
                                                            <button
                                                                style={{ marginTop: '10px' }}
                                                                className="btn btn-primary btn-md"
                                                                onClick={() => {
                                                                    setCurrentQuestion(currentQuestion - 1);
                                                                    checkCorrectAnswer();
                                                                    reset('PREVIOUS');
                                                                }}
                                                            >
                                                                PREVIOUS
                                                            </button>
                                                        )}

                                                        {next && (
                                                            <button
                                                                style={{ marginLeft: "10px", marginTop: '10px' }}
                                                                className="btn btn-primary btn-md"
                                                                onClick={() => {
                                                                    setCurrentQuestion(currentQuestion + 1);
                                                                    checkCorrectAnswer();
                                                                    reset('NEXT');
                                                                }}
                                                            >
                                                                NEXT
                                                            </button>
                                                        )}

                                                        {currentQuestion ===
                                                            candidateSelectedExamQuestions.data &&
                                                            candidateSelectedExamQuestions.data
                                                                .question_data &&
                                                            candidateSelectedExamQuestions.data.question_data
                                                                .length && (
                                                                <button
                                                                    className="btn btn-primary btn-md"
                                                                    onClick={() => finishHandler()}
                                                                >
                                                                    FINISH
                                                                </button>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
