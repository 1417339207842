import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

export const getCandidateExamScheduleList = createAsyncThunk("get/candidate-exam-schedule", async (filter) => {
    const res = await dataService.candidate_exam_schedule_list(filter);
    const resp = res.data;
    return resp;
});
export const createCandidateExamSchedule = createAsyncThunk("create/candidate-exam-schedule", async (data) => {
    const res = await dataService.candidate_exam_schedule_create(data);
    const resp = res.data;
    return resp;
});
export const updateCandidateExamScheduleList = createAsyncThunk("update/candidate-exam-schedule", async (data) => {
    const res = await dataService.candidate_exam_schedule_update(data.ID, data.addvalues);
    const resp = res.data;
    return resp;
});
export const editCandidateExamScheduleList = createAsyncThunk("edit/candidate-exam-schedule", async (data) => {
    const res = await dataService.candidate_exam_schedule_edit(data);
    const resp = res.data;
    return resp;
});
export const deleteCandidateExamSchedule = createAsyncThunk("delete/candidate-exam-schedule", async (data) => {
    const res = await dataService.candidate_exam_schedule_archive(data);
    const resp = res.data;
    return resp;
});

// export const setCandidateSelectedExam = (data) => {
//     return data;
// }

export const getExamQuestions = createAsyncThunk("get/exam-questions", async (data) => {
    const res = await dataService.get_exam_questions(data);
    const resp = res.data;
    //console.log("Questions:",resp);
    return resp;
});

export const getStatusScheduleExam = createAsyncThunk("schedule-status/exam", async (data) => {
    const res = await dataService.get_exam_schedule_status(data.exam_schedule_id, data.schedule_status);
    const resp = res.data;
    return resp;
});

export const postExamCompile = createAsyncThunk("post/compile-exam", async (data) => {
    const res = await dataService.exam_compile_post(data);
    const resp = res.data;
    return resp;
});

export const updateExamCompile = createAsyncThunk("update/compile-exam", async (data) => {
    const res = await dataService.exam_compile_update(data.ID, data.data);
    const resp = res.data;
    return resp;
});

export const completeCandidateExamPost = createAsyncThunk("exam/candidate-complete", async (data) => {
    const res = await dataService.exam_post_submit(data);
    const resp = res.data;
    return resp;
});


const candidateExamSlice = createSlice({
    name: "candidate-exam",
    initialState: {
        candidateExamScheduleList: [],
        candidateExamScheduleCreate: [],
        candidateExamScheduleUpdate: [],
        candidateExamScheduleEdit: [],
        candidateExamScheduleDelete: [],
        candidateSelectedExam: [],
        candidateSelectedExamQuestions: [],
        status: STATUSES.IDLE,
        statusScheduleExam: [],
        compileExamPost: [],
        compileExamUpdate: [],
        examResultData:[],
        error: ''
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get list
            .addCase(getCandidateExamScheduleList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getCandidateExamScheduleList.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_candidateExamScheduleList`;
                    state.candidateExamScheduleList = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(getCandidateExamScheduleList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })

            //get exam questions
            .addCase(getExamQuestions.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getExamQuestions.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_candidateExamQuestions`;
                    state.candidateSelectedExamQuestions = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(getExamQuestions.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })

            //get status exam schedule
            .addCase(getStatusScheduleExam.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getStatusScheduleExam.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_statusScheduleExam`;
                    state.statusScheduleExam = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(getStatusScheduleExam.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })

            //get candidateExamScheduleCreate
            .addCase(createCandidateExamSchedule.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(createCandidateExamSchedule.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_candidateExamScheduleCreate`;
                    state.candidateExamScheduleCreate = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(createCandidateExamSchedule.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })
            //update
            .addCase(updateCandidateExamScheduleList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(updateCandidateExamScheduleList.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_candidateExamScheduleUpdate`;
                    state.candidateExamScheduleUpdate = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(updateCandidateExamScheduleList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })
            //get delete
            .addCase(deleteCandidateExamSchedule.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(deleteCandidateExamSchedule.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_candidateExamScheduleDelete`;
                    state.candidateExamScheduleDelete = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(deleteCandidateExamSchedule.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })
            //get edit
            .addCase(editCandidateExamScheduleList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(editCandidateExamScheduleList.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_candidateExamScheduleEdit`;
                    state.candidateExamScheduleEdit = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(editCandidateExamScheduleList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })

            //get compile post 
            .addCase(postExamCompile.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postExamCompile.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_compileExamPost`;
                    state.compileExamPost = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(postExamCompile.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })


            //get compile update 
            .addCase(updateExamCompile.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(updateExamCompile.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_compileExamUpdate`;
                    state.compileExamUpdate = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(updateExamCompile.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })

             //get exam result data update 
             .addCase(completeCandidateExamPost.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(completeCandidateExamPost.fulfilled, (state, action) => {
                let result = action.payload;
                if (result.status === 200) {
                    state.status = `${STATUSES.SUCCESS}_examResultData`;
                    state.examResultData = action.payload;
                } else {
                    state.status = STATUSES.FAILURE
                    state.error = action.payload
                }
            })
            .addCase(completeCandidateExamPost.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })
    },
});


export default candidateExamSlice.reducer;