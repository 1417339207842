import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

// get all menu list
export const getMenuList = createAsyncThunk(
    "get/menu-list",
    async (data) => {
        const res = await dataService.menuList(data);
        const resp = res.data;
        return resp;
    });
export const postBaseMenu = createAsyncThunk(
    "post/base-menu",
    async (data) => {
        const res = await dataService.postmenu(data);
        const resp = res.data;
        return resp;
    });

export const getRoleMenuList = createAsyncThunk(
    "get/role-menu-list",
    async (data) => {
        const res = await dataService.getRoleMenu(data);
        const resp = res.data;
        return resp;
    });
// base/menu-role
export const postSingleBaseMenu = createAsyncThunk(
    "post-single/base-menu",
    async (data) => {
        const res = await dataService.postSingleBaseMenu(data);
        const resp = res.data;
        return resp;
    });
export const postAssignMenuRole = createAsyncThunk(
    "post-multipe/base-menu",
    async (data) => {
        const res = await dataService.postAssignMenuRole(data);
        const resp = res.data;
        return resp;
    });
//get all menu for parent
export const getAllMenuList = createAsyncThunk("get/all-menu-list", async (data) => {
    const res = await dataService.menuList(data);
    const resp = res.data;
    return resp;
});

export const getAsigneRoleMenuList = createAsyncThunk("get/assigne-role-menu-list", async (data) => {
    const res = await dataService.getRoleMenu(data);
    const resp = res.data;
    return resp;
});

export const postUploadResume = createAsyncThunk("post/upload-resume", async (data) => {
    const res = await dataService.postResume(data);
    const resp = res.data;
    return resp;
});

//////questiontype apis///////
export const getAllQuestionTypeList = createAsyncThunk("questiontype/all-list", async (data) => {
    const res = await dataService.all_questiontype_list(data);
    return res.data;
});

export const getQuestionTypeList = createAsyncThunk("questiontype/list", async (data) => {
    const res = await dataService.questionType_list(data);
    return res.data;
});

export const questiontypeCreate = createAsyncThunk("questiontype/create", async (data) => {
    const res = await dataService.questionType_create(data);
    return res.data;
});

export const questiontypeEdit = createAsyncThunk("questiontype/edit", async (id) => {
    const res = await dataService.questionType_edit(id);
    return res.data;
});

export const questiontypeUpdate = createAsyncThunk("questiontype/update", async (data) => {
    const res = await dataService.questionType_update(data.ID, data.addvalues);
    return res.data;
});

export const questiontypeArchive = createAsyncThunk("questiontype/archive", async (id) => {
    const res = await dataService.questionType_archive(id);
    return res.data;
});

//////Technology apis///////
export const getAllTechnologyList = createAsyncThunk("technology/all-list", async (data) => {
    const res = await dataService.all_technology_list(data);
    return res.data;
});

export const getTechnologyList = createAsyncThunk("technology/list", async (data) => {
    const res = await dataService.technology_list(data);
    return res.data;
});

export const technologyCreate = createAsyncThunk("technology/create", async (data) => {
    const res = await dataService.technology_create(data);
    return res.data;
});

export const technologyEdit = createAsyncThunk("technology/edit", async (id) => {
    const res = await dataService.technology_edit(id);
    return res.data;
});

export const technologyUpdate = createAsyncThunk("technology/update", async (data) => {
    const res = await dataService.technology_update(data.ID, data.addvalues);
    return res.data;
});

export const technologyArchive = createAsyncThunk("technology/archive", async (id) => {
    const res = await dataService.technology_archive(id);
    return res.data;
});
//////QuestionLevel apis///////
export const getAllQuestionLevelList = createAsyncThunk("questionlevel/all-list", async (data) => {
    const res = await dataService.all_questionlevel_list(data);
    return res.data;
});

export const getQuestionLevelList = createAsyncThunk("questionlevel/list", async (data) => {
    const res = await dataService.questionlevel_list(data);
    return res.data;
});

export const questionlevelCreate = createAsyncThunk("questionlevel/create", async (data) => {
    const res = await dataService.questionlevel_create(data);
    return res.data;
});

export const questionlevelEdit = createAsyncThunk("questionlevel/edit", async (id) => {
    const res = await dataService.questionlevel_edit(id);
    return res.data;
});

export const questionlevelUpdate = createAsyncThunk("questionlevel/update", async (data) => {
    const res = await dataService.questionlevel_update(data.ID, data.addvalues);
    return res.data;
});

export const questionlevelArchive = createAsyncThunk("questionlevel/archive", async (id) => {
    const res = await dataService.questionlevel_archive(id);
    return res.data;
});


//////questionSet apis///////
export const getQuestionSetList = createAsyncThunk("questionset/all-list", async (data) => {
    const res = await dataService.questionSet_list(data);
    return res.data;
});

export const questionSetCreate = createAsyncThunk("questionset/create", async (data) => {
    const res = await dataService.questionSet_create(data);
    return res.data;
});

export const questionSetEdit = createAsyncThunk("questionset/edit", async (id) => {
    const res = await dataService.questionSet_edit(id);
    return res.data;
});

export const questionSetUpdate = createAsyncThunk("questionset/update", async (data) => {
    const res = await dataService.questionSet_update(data.ID, data.addvalues);
    return res.data;
});

export const questionSetArchive = createAsyncThunk("questionSet/archive", async (id) => {
    const res = await dataService.questionSet_archive(id);
    return res.data;
});

//////question apis///////
export const getQuestionList = createAsyncThunk("question/all-list", async (data) => {
    const res = await dataService.question_list(data);
    return res.data;
});

export const questionCreate = createAsyncThunk("question/create", async (data) => {
    const res = await dataService.question_create(data);
    return res.data;
});

export const questionEdit = createAsyncThunk("question/edit", async (id) => {
    const res = await dataService.question_edit(id);
    return res.data;
});

export const questionUpdate = createAsyncThunk("question/update", async (data) => {
    const res = await dataService.question_update(data.ID, data.addvalues);
    return res.data;
});

export const questionArchive = createAsyncThunk("question/archive", async (id) => {
    const res = await dataService.question_archive(id);
    return res.data;
});

export const downloadQuestionSample = createAsyncThunk("download-question-sample", async () => {
    const res = await dataService.download_samplefile_question();
    return res.data;
});

export const importQusetionFile = createAsyncThunk("import-question", async (id) => {
    const res = await dataService.import_file_question(id);
    return res.data;
});

export const postAnswerOptions = createAsyncThunk("answer-options/create", async (data) => {
    const res = await dataService.answer_option_create(data);
    return res.data;
});

export const updateAnswerOptions = createAsyncThunk("answer-options/update", async (data) => {
    const res = await dataService.answer_option_update(data.ID, data.addvalues);
    return res.data;
});

export const getAnswerOptions = createAsyncThunk("answer-options", async (filter) => {
    const res = await dataService.answer_option_list(filter);
    return res.data;
});

export const deleteAnswerOptions = createAsyncThunk("answer-options/archive", async (id) => {
    const res = await dataService.answer_option_delete(id);
    return res.data;
});

const masterSlice = createSlice({
    name: "master",
    initialState: {
        status: 'idle',
        error: '',

        menuList: [],
        createMenu: [],
        createRoleMenu: [],
        roleMenuList: [],
        createAssignRoleMenu: [],
        allMenuList: [],
        assigneMenu: [],

        questiontype_AllList: [],
        questiontype_Create: [],
        questiontype_List: [],
        questiontype_Edit: [],
        questiontype_Update: [],
        questiontype_Archive: [],

        technology_AllList: [],
        technology_Create: [],
        technology_List: [],
        technology_Edit: [],
        technology_Update: [],
        technology_Archive: [],

        questionlevel_AllList: [],
        questionlevel_Create: [],
        questionlevel_List: [],
        questionlevel_Edit: [],
        questionlevel_Update: [],
        questionlevel_Archive: [],

        questionset_List: [],
        questionset_Create: [],
        questionset_Edit: [],
        questionset_Update: [],
        questionset_Archive: [],

        question_List: [],
        question_Create: [],
        question_Edit: [],
        question_Update: [],
        question_Archive: [],

        download_question_sample: [],
        import_question_file: [],

        answer_options_Create: [],
        answer_options_List: [],
        answer_options_Update: [],
        answer_options_Delete: [],

    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get menu list 
            .addCase(getMenuList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getMenuList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.menuList = action.payload;

            })
            .addCase(getMenuList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })
            //post menu  
            .addCase(postBaseMenu.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postBaseMenu.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createMenu`;
                state.createMenu = action.payload;

            })
            .addCase(postBaseMenu.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_createMenu`;
                state.error = action.error
            })
            //post and update single menu role
            .addCase(postSingleBaseMenu.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postSingleBaseMenu.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createRoleMenu`;
                state.createRoleMenu = action.payload;

            })
            .addCase(postSingleBaseMenu.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_createRoleMenu`;
                state.error = action.error
            })
            //get menu role list
            .addCase(getRoleMenuList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getRoleMenuList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_roleMenuList`;
                state.roleMenuList = action.payload;

            })
            .addCase(getRoleMenuList.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_roleMenuList`;
                state.error = action.error
            })

            //post Assign menu role 
            .addCase(postAssignMenuRole.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postAssignMenuRole.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createAssignRoleMenu`;
                state.createAssignRoleMenu = action.payload;
            })
            .addCase(postAssignMenuRole.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_createAssignRoleMenu`;
                state.error = action.error
            })

            // get parent menu
            .addCase(getAllMenuList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.allMenuList = action.payload;

            })

            //get assigne menu list by role
            .addCase(getAsigneRoleMenuList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAsigneRoleMenuList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_roleMenuList`;
                state.dataLoader = STATUSES.SUCCESS;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res.map(e => {
                    return newArray.push({
                        id: e.id,
                        created_by: e.created_by,
                        created_on: e.created_on,
                        menu_id: e.menu_id,
                        menu: e.menu && e.menu.url_slug,
                        menu_title: e.menu && e.menu.title,
                        menu_status: e.menu && e.menu.status,
                        parent_menu_id: e.parent_menu_id,
                        parent_menu: e.parent_menu && e.parent_menu.url_slug,
                        parent_menu_status: e.parent_menu && e.parent_menu.status,
                        role_id: e.role_id,
                        updated_by: e.updated_by,
                        updated_on: e.updated_on,
                        status: e.status
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray.filter(e => e.menu_status === true && (e.parent_menu === null || e.parent_menu_status === true)), "count": count } }
                }
                state.assigneMenu = newArray2 && newArray2.data ? newArray2 : action.payload;
                localStorage.setItem('menuData', JSON.stringify(newArray2))
            })
            .addCase(getAsigneRoleMenuList.rejected, (state, action) => {
                state.status = `${STATUSES.FAILURE}_roleMenuList`;
                state.error = action.error
            })

            //////////questiontype store section/////////
            ///all questiontype  list
            .addCase(getAllQuestionTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllQuestionTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questiontype_AllList = action.payload;
            })
            .addCase(getAllQuestionTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd questiontype list
            .addCase(getQuestionTypeList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getQuestionTypeList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questiontype_List = action.payload;
            })
            .addCase(getQuestionTypeList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///questiontype create
            .addCase(questiontypeCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questiontypeCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questiontype_Create`;
                state.questiontype_Create = action.payload;
            })
            .addCase(questiontypeCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questiontype edit
            .addCase(questiontypeEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questiontypeEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questiontype_Edit = action.payload;
            })
            .addCase(questiontypeEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questiontype Update
            .addCase(questiontypeUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questiontypeUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questiontype_Update`;
                state.questiontype_Update = action.payload;
            })
            .addCase(questiontypeUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questiontype archive
            .addCase(questiontypeArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questiontypeArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questiontype_Archive`;
                state.questiontype_Archive = action.payload;
            })
            .addCase(questiontypeArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //////////Technology store section/////////
            ///all technology  list
            .addCase(getAllTechnologyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllTechnologyList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.technology_AllList = action.payload;
            })
            .addCase(getAllTechnologyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd technology list
            .addCase(getTechnologyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getTechnologyList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.technology_List = action.payload;
            })
            .addCase(getTechnologyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///technology create
            .addCase(technologyCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(technologyCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_technology_Create`;
                state.technology_Create = action.payload;
            })
            .addCase(technologyCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///technology edit
            .addCase(technologyEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(technologyEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.technology_Edit = action.payload;
            })
            .addCase(technologyEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///technology Update
            .addCase(technologyUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(technologyUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_technology_Update`;
                state.technology_Update = action.payload;
            })
            .addCase(technologyUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///technology archive
            .addCase(technologyArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(technologyArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_technology_Archive`;
                state.technology_Archive = action.payload;
            })
            .addCase(technologyArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            //////////QuestionLevel store section/////////
            ///all questionlevel  list
            .addCase(getAllQuestionLevelList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllQuestionLevelList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questionlevel_AllList = action.payload;
            })
            .addCase(getAllQuestionLevelList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd questionlevel list
            .addCase(getQuestionLevelList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getQuestionLevelList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questionlevel_List = action.payload;
            })
            .addCase(getQuestionLevelList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///questionlevel create
            .addCase(questionlevelCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionlevelCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questionlevel_Create`;
                state.questionlevel_Create = action.payload;
            })
            .addCase(questionlevelCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questionlevel edit
            .addCase(questionlevelEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionlevelEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questionlevel_Edit = action.payload;
            })
            .addCase(questionlevelEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questionlevel Update
            .addCase(questionlevelUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionlevelUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questionlevel_Update`;
                state.questionlevel_Update = action.payload;
            })
            .addCase(questionlevelUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questionlevel archive
            .addCase(questionlevelArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionlevelArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questionlevel_Archive`;
                state.questionlevel_Archive = action.payload;
            })
            .addCase(questionlevelArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questionSet store section///
            //all questionSet list
            .addCase(getQuestionSetList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getQuestionSetList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.questionset_List = action.payload;
                state.status = STATUSES.SUCCESS;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res.map(e => {
                    return newArray.push({
                        id: e.id,
                        set_name: e.set_name,
                        maximum_time: e.maximum_time,
                        no_of_question: e.no_of_question,
                        set_key: e.set_key,
                        technology_id: e.technology_id,
                        technology: e.technology && e.technology.name,
                        level_id: e.level_id,
                        level: e.level && e.level.level_name,
                        created_by: e.created_by,
                        created_on: e.created_on,
                        updated_by: e.updated_by,
                        updated_on: e.updated_on,
                        status: e.status
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.questionset_List = newArray2 && newArray2.data ? newArray2 : action.payload;
            })
            .addCase(getQuestionSetList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            //questionSet create
            .addCase(questionSetCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionSetCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questionset_Create`;
                state.questionset_Create = action.payload;
            })
            .addCase(questionSetCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //questionSet edit
            .addCase(questionSetEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionSetEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.questionset_Edit = action.payload;
            })
            .addCase(questionSetEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //questionSet Update
            .addCase(questionSetUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionSetUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questionset_Update`;
                state.questionset_Update = action.payload;
            })
            .addCase(questionSetUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///questionset archive
            .addCase(questionSetArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionSetArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_questionset_Archive`;
                state.questionset_Archive = action.payload;
            })
            .addCase(questionSetArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///question store section///
            //all question list
            .addCase(getQuestionList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getQuestionList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                // state.question_List = action.payload;
                state.status = STATUSES.SUCCESS;
                let newArray = [];
                let newArray2 = []
                let result = action.payload;
                let res = result && result.data && result.data.rows
                let count = result && result.data && result.data.count
                res.map(e => {
                    return newArray.push({
                        ...e,
                        technology_id: e.technology_id,
                        technology: e.technology && e.technology.name,
                        question_level: e.question_level && e.question_level.level_name,
                        question_type: e.question_type && e.question_type.question_type,
                    })
                })
                if (newArray.length > 0) {
                    newArray2 = { "data": { "rows": newArray, "count": count } }
                }
                state.question_List = newArray2 && newArray2.data ? newArray2 : action.payload;
            })
            .addCase(getQuestionList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            //question create
            .addCase(questionCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_question_Create`;
                state.question_Create = action.payload;
            })
            .addCase(questionCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //question edit
            .addCase(questionEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.question_Edit = action.payload;
            })
            .addCase(questionEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //question Update
            .addCase(questionUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_question_Update`;
                state.question_Update = action.payload;
            })
            .addCase(questionUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///question archive
            .addCase(questionArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(questionArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_question_Archive`;
                state.question_Archive = action.payload;
            })
            .addCase(questionArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //download sample file Question
            .addCase(downloadQuestionSample.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(downloadQuestionSample.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_download_question_sample`;
                state.download_question_sample = action.payload;
            })
            .addCase(downloadQuestionSample.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //import file Questions
            .addCase(importQusetionFile.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(importQusetionFile.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_import_question_file`;
                state.import_question_file = action.payload;
            })
            .addCase(importQusetionFile.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            // answer option section start
            //create answer options
            .addCase(postAnswerOptions.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(postAnswerOptions.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_answer_options_Create`;
                state.answer_options_Create = action.payload;
            })
            .addCase(postAnswerOptions.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //get answer options list
            .addCase(getAnswerOptions.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAnswerOptions.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_answer_options_List`;
                state.answer_options_List = action.payload;
            })
            .addCase(getAnswerOptions.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

             //update answer options 
             .addCase(updateAnswerOptions.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(updateAnswerOptions.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_answer_options_Update`;
                state.answer_options_Update = action.payload;
            })
            .addCase(updateAnswerOptions.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///answer options archive
            .addCase(deleteAnswerOptions.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(deleteAnswerOptions.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_answer_options_Delete`;
                state.answer_options_Delete = action.payload;
            })
            .addCase(deleteAnswerOptions.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
    },
});


export default masterSlice.reducer;